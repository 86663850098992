import React from 'react'

export default function UsersIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.54249 8.92432L5.24598 7.5327C5.30424 7.50598 5.35544 7.46221 5.39132 7.40338C5.48719 7.24622 5.4375 7.04109 5.28034 6.94522C4.49137 6.46395 4.00016 5.60797 4.00016 4.66683C4.00016 3.19407 5.19407 2.00016 6.66683 2.00016C7.5614 2.00016 8.38043 2.44359 8.87409 3.16994C8.97757 3.3222 9.18489 3.36174 9.33715 3.25825C9.48941 3.15477 9.52895 2.94745 9.42546 2.7952C8.80897 1.88813 7.78456 1.3335 6.66683 1.3335C4.82588 1.3335 3.3335 2.82588 3.3335 4.66683C3.3335 5.64837 3.76141 6.55636 4.47587 7.17931L2.23737 8.33157C1.68234 8.61728 1.3335 9.18919 1.3335 9.81344V10.3335C1.3335 11.254 2.0797 12.0002 3.00021 12.0002C3.1843 12.0002 3.33354 11.8509 3.33354 11.6668C3.33354 11.4827 3.1843 11.3335 3.00021 11.3335C2.44788 11.3335 2.00016 10.8858 2.00016 10.3335V9.81344C2.00016 9.43889 2.20947 9.09574 2.54249 8.92432ZM5.25303 11.6152L7.97434 10.3782C8.38943 10.5638 8.84941 10.667 9.3335 10.667C9.81758 10.667 10.2776 10.5638 10.6927 10.3782L13.414 11.6152C13.771 11.7774 14.0002 12.1334 14.0002 12.5255V13.0003C14.0002 13.5526 13.5524 14.0003 13.0002 14.0003H5.66683C5.11454 14.0003 4.66683 13.5526 4.66683 13.0003V12.5255C4.66683 12.1334 4.89603 11.7774 5.25303 11.6152ZM7.2816 9.96063C6.50156 9.35052 6.00016 8.40056 6.00016 7.3335C6.00016 5.49255 7.49255 4.00016 9.3335 4.00016C11.1744 4.00016 12.6668 5.49255 12.6668 7.3335C12.6668 8.40056 12.1654 9.35052 11.3854 9.96063L13.6898 11.0081C14.2848 11.2786 14.6668 11.8718 14.6668 12.5254V13.0002C14.6668 13.9206 13.9206 14.6668 13.0002 14.6668H5.66683C4.74635 14.6668 4.00016 13.9206 4.00016 13.0002V12.5254C4.00016 11.8718 4.38217 11.2786 4.97716 11.0081L7.2816 9.96063ZM12.0002 7.3335C12.0002 8.80626 10.8063 10.0002 9.3335 10.0002C7.86074 10.0002 6.66683 8.80626 6.66683 7.3335C6.66683 5.86074 7.86074 4.66683 9.3335 4.66683C10.8063 4.66683 12.0002 5.86074 12.0002 7.3335Z"
        fill="#F27C38"
      />
    </svg>
  )
}
