import { css } from 'styled-components'

const fontFaces = css`
  @font-face {
    font-family: 'Century Gothic';
    src: url('/assets/fonts/CenturyGothic.woff2') format('woff2'),
      url('/assets/fonts/CenturyGothic.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Century Gothic';
    src: url('/assets/fonts/CenturyGothic-Bold.woff2') format('woff2'),
      url('/assets/fonts/CenturyGothic-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Century Gothic';
    src: url('/assets/fonts/CenturyGothic-Italic.woff2') format('woff2'),
      url('/assets/fonts/CenturyGothic-Italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: 'Century Gothic';
    src: url('/assets/fonts/CenturyGothic-BoldItalic.woff2') format('woff2'),
      url('/assets/fonts/CenturyGothic-BoldItalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
  }

  @font-face {
    font-family: 'Futura';
    src: url('/assets/fonts/Futura.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Circular Std';
    src: url('/assets/fonts/CircularStd.woff2') format('woff2');
    font-weight: 400;
    font-style: normat;
  }
`

export default fontFaces
