import React from 'react'

export default function ImageIcon() {
  return (
    <svg
      width="28"
      height="23"
      viewBox="0 0 28 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.470226 4.27736C0.470226 2.42094 1.97515 0.916016 3.83157 0.916016H23.9996C25.8561 0.916016 27.361 2.42094 27.361 4.27736V19.0673C27.361 20.9237 25.8561 22.4286 23.9996 22.4286H3.83157C1.97515 22.4286 0.470226 20.9237 0.470226 19.0673V16.3821C0.470211 16.3795 0.470211 16.3769 0.470226 16.3743V4.27736ZM1.81475 16.6566V19.0673C1.81475 20.1811 2.71771 21.0841 3.83156 21.0841H23.9996C24.6594 21.0841 25.2452 20.7673 25.6131 20.2775C25.588 20.2586 25.5639 20.2377 25.5411 20.2149L19.966 14.6398L16.4078 18.1981C16.1452 18.4606 15.7196 18.4606 15.457 18.1981L7.86517 10.6062L1.81475 16.6566ZM26.0164 18.7888L20.4414 13.2137C20.1788 12.9512 19.7532 12.9512 19.4906 13.2137L15.9324 16.772L8.34054 9.18012C8.078 8.91759 7.65234 8.91759 7.38981 9.18012L1.81475 14.7552V4.27734C1.81475 3.16349 2.71771 2.26053 3.83156 2.26053H23.9996C25.1135 2.26053 26.0164 3.16349 26.0164 4.27734V18.7888ZM21.9828 4.94961H19.2937C18.5512 4.94961 17.9492 5.55158 17.9492 6.29414V8.98322C17.9492 9.72579 18.5512 10.3278 19.2937 10.3278H21.9828C22.7254 10.3278 23.3274 9.72579 23.3274 8.98322V6.29414C23.3274 5.55158 22.7254 4.94961 21.9828 4.94961ZM19.2937 6.29414V8.98322H21.9828V6.29414H19.2937Z"
        fill="#7F7F7F"
      />
    </svg>
  )
}
