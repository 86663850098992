import React, { useState } from 'react'
import rewardsApi from '../../../api/rewards'
import ImageUploader from '../../../components/ImageUploader'
import InputField from '../../../components/InputField'
import Modal from '../../../components/Modal'
import { Heading2Bold } from '../../../design-system/typography'
import { Button, TextButton } from '../../../design-system/utils'
import Reward from '../../../types/Reward'
import validateWebsite from '../../../utils/ValidateWebsite'
import { Form, InputFields, Error } from './style'

type EditRewardProps = {
  onClose: () => void
  onUpdate: () => void
  reward: Reward
}

function EditReward({ onClose, onUpdate, reward }: EditRewardProps) {
  const [thumbnail, setThumbnail] = useState(null)
  const [title, setTitle] = useState(reward.title)
  const [shopName, setShopName] = useState(reward.shopName)
  const [website, setWebsite] = useState(reward.website)
  const [code, setCode] = useState(reward.code)
  const [errorMessage, setErrorMessage] = useState(null)
  const [invalidWebsite, setInvalidWebsite] = useState(false)

  const submitReward = async (e) => {
    e.preventDefault()

    if (!validateWebsite(website)) {
      setInvalidWebsite(true)
      return
    }

    setInvalidWebsite(false)
    rewardsApi
      .editReward(thumbnail, title, shopName, website, code, reward.id)
      .then(() => {
        onUpdate()
        onClose()
      })
      .catch((err) => {
        if (err.response.status === 422) {
          setErrorMessage(
            'Unable to save changes. Please make sure that all fields are filled.'
          )
        } else {
          setErrorMessage('Something went wrong. Please try again.')
        }
      })
  }

  return (
    <Modal onClose={onClose} width="695px">
      <Heading2Bold>Edit reward</Heading2Bold>
      <Form onSubmit={submitReward}>
        <InputFields>
          <ImageUploader
            setImage={setThumbnail}
            defaultImageUrl={reward.imageUrl}
          />
          <div>
            <InputField
              placeholder="Title"
              onChangeEvent={setTitle}
              defaultValue={title}
            />
            <InputField
              placeholder="Shop/brand name"
              onChangeEvent={setShopName}
              defaultValue={shopName}
            />
            <InputField
              placeholder="Website"
              onChangeEvent={setWebsite}
              defaultValue={website}
              error={invalidWebsite}
              errorMessage="Enter a valid website"
            />
            <InputField
              placeholder="Discount code"
              onChangeEvent={setCode}
              defaultValue={code}
            />
          </div>
        </InputFields>
        <Error>{errorMessage}</Error>
        <Button secondary type="submit">
          Edit reward
        </Button>
        <TextButton type="button" onClick={onClose}>
          Cancel
        </TextButton>
      </Form>
    </Modal>
  )
}
export default EditReward
