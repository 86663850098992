export enum CouponStatus {
  donated = 'donated',
  limited = 'limited',
}

export enum RevenuecatEntitlementDuration {
  daily = 'daily',
  threeDay = 'three_day',
  weekly = 'weekly',
  monthly = 'monthly',
  twoMonth = 'two_month',
  threeMonth = 'three_month',
  sixMonth = 'six_month',
  yearly = 'yearly',
  lifetime = 'lifetime',
}

type CouponApiData = {
  title: string
  code: string
  startDateTime: string
  endDateTime: string
  maxRedemptions: number
  appleCouponCode: string
  googleCouponCode: string
  entitlementDuration: RevenuecatEntitlementDuration
  status: CouponStatus
  quantity?: number
}

export default CouponApiData
