import React, { useContext } from 'react'
import { Route } from 'react-router-dom'
import UserList from './pages/UserList'
import Login from './pages/Auth/Login'
import ResetMailSender from './pages/Auth/ResetMailSender'
import Club from './pages/Club'
import Chat from './pages/Chat'
import TrainingPlans from './pages/TrainingPlans'
import Rewards from './pages/Rewards'
import './design-system/normalize.css'
import PasswordReset from './pages/Auth/PasswordReset'
import Header from './components/Header'
import StravaCallback from './pages/StravaCallback'
import Coupons from './pages/Coupons'
import UserTrainingPlans from './pages/UserTrainingPlans'
import TrainingSessions from './pages/TrainingSessions'
import Purchase from './pages/Purchase'
import PurchaseSuccess from './pages/PurchaseSuccess'
import Footer from './components/Footer'
import PurchaseFailed from './pages/PurchaseSuccess/PurchaseFailed'

import { AuthContext } from './pages/Auth/AuthProvider'

const Routes = () => {
  const { loggedInUser } = useContext(AuthContext)

  return (
    <>
      <Route path="*" component={Header} />

      <Route path="/login" component={Login} />
      <Route path="/reset" component={ResetMailSender} />
      <Route path="/passwordreset" component={PasswordReset} />
      <Route path="/strava-callback" component={StravaCallback} />
      {loggedInUser?.role === 'admin' && (
        <>
          <Route path="/" exact component={UserList} />
          <Route path="/club" component={Club} />
          <Route path="/chat" component={Chat} />
          <Route path="/training-plan" component={TrainingPlans} />
          <Route path="/rewards" component={Rewards} />
          <Route path="/coupons" component={Coupons} />
          <Route path="/users/">
            <Route
              path="/users/:userId/plans"
              exact
              component={UserTrainingPlans}
            />
            <Route
              path="/users/:userId/plans/:planId"
              exact
              component={TrainingSessions}
            />
          </Route>
        </>
      )}
      <Route path="/purchase" component={Purchase} />
      <Route path="/purchase-success" component={PurchaseSuccess} />
      <Route path="/purchase-failed" component={PurchaseFailed} />

      {loggedInUser?.role === 'user' && (
        <>
          <Route path="/" exact component={Purchase} />
        </>
      )}
      {!loggedInUser && <Route path="/" exact component={Login} />}
      <Route path="*" component={Footer} />
    </>
  )
}

export default Routes
