import React from 'react'

export default function EditIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10ZM11.2071 7.5L12.5 8.79289L13.7929 7.5L12.5 6.20711L11.2071 7.5ZM10.5 8.20711L6 12.7071V14H7.29289L11.7929 9.5L10.5 8.20711ZM10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19ZM14.8536 7.14645L12.8536 5.14645C12.6583 4.95118 12.3417 4.95118 12.1464 5.14645L5.14645 12.1464C5.05268 12.2402 5 12.3674 5 12.5V14.5C5 14.7761 5.22386 15 5.5 15H7.5C7.63261 15 7.75979 14.9473 7.85355 14.8536L14.8536 7.85355C15.0488 7.65829 15.0488 7.34171 14.8536 7.14645Z"
        fill="black"
      />
    </svg>
  )
}
