import React, { useEffect, useState } from 'react'
import usersApi from '../../../api/users'
import ChannelInfoHeader from '../../../components/ChannelInfoHeader'
import Modal from '../../../components/Modal'
import { Paragraph2, Paragraph2Bold } from '../../../design-system/typography'
import EditUserChannels from '../EditUserChannels'
import { EditButton, Title, Wrapper } from './style'

type UserChannelsProps = {
  userId: string | number
}

export default function UserChannels({ userId }: UserChannelsProps) {
  const [channels, setChannels] = useState([])
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [triggerUpdate, setTriggerUpdate] = useState(false)

  useEffect(() => {
    usersApi.getClubsByUser(userId).then((res) => setChannels(res.data))
  }, [userId, triggerUpdate])

  return (
    <>
      {editModalOpen && (
        <Modal onClose={() => setEditModalOpen(false)}>
          <EditUserChannels
            joinedChannels={channels}
            userId={userId}
            triggerUpdate={() => setTriggerUpdate((updated) => !updated)}
          />
        </Modal>
      )}
      <Wrapper>
        <Title>
          <Paragraph2Bold>Channels</Paragraph2Bold>
          <EditButton type="button" onClick={() => setEditModalOpen(true)}>
            Edit
          </EditButton>
        </Title>
        {channels.length > 0 ? (
          channels.map((channel) => (
            <ChannelInfoHeader
              name={channel.name}
              memberCount={channel.member_count}
              imageUrl={channel.image}
              key={channel.name}
            />
          ))
        ) : (
          <Paragraph2>No joined channels yet</Paragraph2>
        )}
      </Wrapper>
    </>
  )
}
