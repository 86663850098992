import styled from 'styled-components'

const StatusMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > svg {
    margin-top: ${(p) => p.theme.spacing.lg};
    margin-bottom: ${(p) => p.theme.spacing.sm};
  }

  & > p {
    margin-bottom: ${(p) => p.theme.spacing.xl};
  }
`

export default StatusMessageWrapper
