import React from 'react'

function Checkmark() {
  return (
    <svg
      width="28"
      height="21"
      viewBox="0 0 28 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.82422 9.14L11.3242 18.5L26.8242 2"
        stroke="#F27C38"
        strokeWidth="3"
      />
    </svg>
  )
}

export default Checkmark
