import axios from 'axios'
import ClubApiData from '../types/ClubApiData'

const getClubsWithLimit = ({ limit, offset }) => {
  return axios.get(`/api/admin/club/list?limit=${limit}&offset=${offset}`, {
    withCredentials: true,
  })
}

const getAllClubs = () => {
  return axios.get(`/api/admin/club/list`, {
    withCredentials: true,
  })
}

const createClub = ({
  file,
  headerImage,
  name,
  description,
  clubCode,
  appStoreSubscriptionId,
  playStoreSubscriptionId,
  elevated,
  clubColor,
  gallery,
  externalLinksToCreate,
}: ClubApiData) => {
  const formData = new FormData()
  formData.append('file', file, 'main')
  formData.append('file', headerImage, 'header')
  formData.append('name', name)
  formData.append('description', description)
  formData.append('clubCode', clubCode)
  formData.append('clubColor', clubColor)
  formData.append('appStoreSubscriptionId', appStoreSubscriptionId)
  formData.append('playStoreSubscriptionId', playStoreSubscriptionId)
  formData.append('elevated', String(elevated))
  formData.append(
    'externalLinksToCreate',
    JSON.stringify(externalLinksToCreate)
  )

  // eslint-disable-next-line
  for (let i = 0; i < gallery.length; i++) {
    formData.append('file', gallery[i], gallery[i].name)
  }

  return axios.post('/api/admin/club', formData, {
    withCredentials: true,
  })
}

const deleteClub = (clubId) => {
  return axios.delete(`/api/admin/club/${clubId}`, {
    withCredentials: true,
  })
}

const updateClub = ({
  id,
  file,
  headerImage,
  name,
  description,
  clubCode,
  appStoreSubscriptionId,
  playStoreSubscriptionId,
  elevated,
  clubColor,
  imagesToDelete,
  gallery,
  externalLinksToCreate,
  externalLinksToDelete,
  externalLinksToUpdate,
}: ClubApiData) => {
  const formData = new FormData()

  if (file) formData.append('file', file, 'main')
  if (headerImage) formData.append('file', headerImage, 'header')

  formData.append('name', name)
  formData.append('description', description)
  formData.append('clubCode', clubCode)
  formData.append('clubColor', clubColor)
  formData.append('appStoreSubscriptionId', appStoreSubscriptionId)
  formData.append('playStoreSubscriptionId', playStoreSubscriptionId)
  formData.append('elevated', String(elevated))
  formData.append('imagesToDelete', imagesToDelete.join(','))
  formData.append(
    'externalLinksToCreate',
    JSON.stringify(externalLinksToCreate)
  )
  formData.append(
    'externalLinksToDelete',
    JSON.stringify(externalLinksToDelete)
  )
  formData.append(
    'externalLinksToUpdate',
    JSON.stringify(externalLinksToUpdate)
  )
  // eslint-disable-next-line
  for (let i = 0; i < gallery.length; i++) {
    formData.append('file', gallery[i], gallery[i].name)
  }
  return axios.put(`/api/admin/club/${id}`, formData, {
    withCredentials: true,
  })
}

const getClubById = (id: number) => {
  return axios.get(`/api/admin/club/${id}`, {
    withCredentials: true,
  })
}

const clubApi = {
  getClubsWithLimit,
  getAllClubs,
  createClub,
  deleteClub,
  updateClub,
  getClubById,
}

export default clubApi
