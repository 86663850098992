import styled from 'styled-components'
import {
  ErrorMessage,
  Heading5Bold,
  Label,
} from '../../../design-system/typography'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${(p) => p.theme.spacing.m};

  > button {
    margin-top: ${(p) => p.theme.spacing.m};
  }
`

export const InputFields = styled.div`
  display: flex;
  align-self: flex-start;

  > div {
    margin-right: ${(p) => p.theme.spacing.xl};
  }
`

export const Error = styled(ErrorMessage)`
  align-self: center;
  margin-top: ${(p) => p.theme.spacing.xs};
`

export const CheckBoxWrapper = styled.div<{ main: boolean }>`
  display: flex;
  align-items: center;
  margin: ${(p) => p.theme.spacing.sm} 0;
  ${(p) => !p.main && `margin-left: ${p.theme.spacing.m};`}
  > p {
    margin-left: ${(p) => p.theme.spacing.sm};
  }
`

export const DropdownWrapper = styled.div`
  display: flex;

  > div {
    margin-left: ${(p) => p.theme.spacing.sm};
  }
`

export const SubHeader = styled(Heading5Bold)`
  max-width: 290px;
  margin-top: ${(p) => p.theme.spacing.sm};
`

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${(p) => p.theme.spacing.m};

  > div {
    margin-left: ${(p) => p.theme.spacing.xs};
  }
`

export const SwitchLabel = styled(Label)`
  color: ${(p) => p.theme.colors.grays[0]};
`
