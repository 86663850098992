import styled from 'styled-components'

export const FooterWrap = styled.footer`
  border-top: 1px solid #ccccc;
  padding: 56px 48px 48px;
  .logo {
    display: flex;
    max-width: 160px;
  }

  a.email {
    display: flex;
    margin: 16px 0;
    color: #ff5700;
    cursor: pointer;
  }
`

export const IconsWrap = styled.div`
  a {
    color: #ff5700;
    margin-right: 16px;
  }
`

export const LinksWrap = styled.div`
  margin-top: 40px;
  font-size: 14px;
  font-family: Circular Std;
  font-weight: 450;
  color: #ff5700;

  .delimiter {
    margin: 0 10px;
  }
  a {
    text-decoration-line: underline;
    cursor: pointer;
    color: #ff5700;
  }
`
