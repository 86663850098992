import React from 'react'
import Spinner from './style'

function Loader() {
  return (
    <Spinner>
      <img src="/assets/images/loader.png" alt="Loader icon" />
    </Spinner>
  )
}
export default Loader
