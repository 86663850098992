import React, { useState } from 'react'
import rewardsApi from '../../../api/rewards'
import EditDropdown from '../../../components/EditDropdown'
import {
  Heading2,
  Heading5,
  Paragraph2,
  PrimaryLink,
} from '../../../design-system/typography'
import Reward from '../../../types/Reward'
import EditReward from '../EditReward'
import { Card, TextWrapper, Image, ActionsWrapper } from './style'

type RewardCardProps = {
  reward: Reward
  triggerUpdate: () => void
}

function RewardCard({ reward, triggerUpdate }: RewardCardProps) {
  const [modalOpen, setModalOpen] = useState(false)

  const deleteReward = () => {
    rewardsApi.deleteReward(reward.id).then(() => triggerUpdate())
  }

  return (
    <Card>
      <ActionsWrapper>
        <EditDropdown
          editLabel="Edit"
          deleteLabel="Delete"
          deleteAction={deleteReward}
          editAction={() => setModalOpen(true)}
        />
      </ActionsWrapper>
      <Image src={reward.imageUrl} alt={reward.title} />
      <TextWrapper>
        <Heading2>{reward.title}</Heading2>
        <Heading5>{reward.shopName}</Heading5>
        <PrimaryLink href={reward.website} target="_blank">
          Visit website
        </PrimaryLink>
        <Paragraph2>
          Discount code: <strong>{reward.code}</strong>
        </Paragraph2>
      </TextWrapper>
      {modalOpen && (
        <EditReward
          reward={reward}
          onClose={() => setModalOpen(false)}
          onUpdate={triggerUpdate}
        />
      )}
    </Card>
  )
}
export default RewardCard
