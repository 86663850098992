import React from 'react'
import { useLocation } from 'react-router-dom'
import { CoopahLogo, PageWrap, Heading5 } from './style'
import { Button, PageWrapper } from '../../design-system/utils'
import Logo from '../../assets/images/logo-big.png'

const StravaCallback = () => {
  const { search } = useLocation()

  const handleRedirect = () => {
    if (search) {
      window.open(`coopah://${search}`)
    }
  }

  return (
    <PageWrapper>
      <PageWrap>
        <CoopahLogo src={Logo} alt="logo" />
        <Heading5>You’re nearly there!</Heading5>
        <Heading5>Tap connect to add Strava to your Coopah account. </Heading5>
        <Button primary onClick={handleRedirect}>
          Connect
        </Button>
      </PageWrap>
    </PageWrapper>
  )
}

export default StravaCallback
