import styled from 'styled-components'

export const ImagePlaceholder = styled.div`
  height: 160px;
  width: 140px;
  background: ${(p) => p.theme.colors.grays[2]};
  margin: 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const FileInput = styled.input`
  display: none;
`

export const UploadButton = styled.label`
  text-decoration: underline;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  display: block;
  margin-bottom: 8px;
`

export const ImagePreview = styled.img`
  height: 160px;
  width: 140px;
  object-fit: cover;
  display: block;
  margin: 8px 0;
`

export const GalleryImagePreview = styled.img`
  height: 80px;
  width: 70px;
  object-fit: cover;
  display: block;
  margin: 8px 0;
`
export const GalleryImageWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const GalleryImageItem = styled.div`
  position: relative;

  & > button {
    background: none;
    border: none;
    position: absolute;
    cursor: pointer;
    right: -16px;
    top: -7px;
  }
`
