export enum SportTypes {
  running = 'Running',
  strengthAndConditioning = 'Strength and Conditioning',
  general = 'General',
  rest = 'Rest',
}
export enum ActivityTypes {
  easyRun = 'Easy Run',
  longRun = 'Long Run',
  tempoRun = 'Tempo Run',
  intervalSession = 'Interval Session',
  strengthVideo = 'Strength Videos',
  strengthGifs = 'Strength GIFs',
  yoga = 'Yoga',
  rest = 'Rest',
  steadyRun = 'Steady Run',
  race = 'Race',
}
export enum SessionPhases {
  buildUp = 'Build Up',
  base = 'Base',
  strength = 'Strength',
  speed = 'Speed',
  taper = 'Taper',
}
export enum SessionStatus {
  hidden = 'hidden',
  active = 'active',
  replacedByTaper = 'replacedByTaper',
  taper = 'taper',
}

export type TrainingSessionInterval = {
  id: number

  title: string

  intervalNo: number

  goalTimeMs?: number

  goalDistanceMeters?: number

  goalPaceMsPerKm?: number

  goalPaceExpression?: string

  recordedTimeMs?: number

  recordedDistanceMeters?: number

  recordedPaceMsPerKm?: number

  trainingSessionId: number

  readonly createdAt: Date

  readonly updatedAt: Date
}

export type TrainingSession = {
  id: number
  title: string

  week: number

  day: number

  date: Date

  time?: string

  description?: string

  embedUrl?: string

  completed: boolean

  sportType?: SportTypes

  activityType?: ActivityTypes

  phase?: SessionPhases

  templateOrder?: number

  status?: SessionStatus

  trainingPlanId: number

  createdAt: Date

  updatedAt: Date

  //   thirdPartyWorkouts: ThirdPartyWorkouts[]

  //   trainingPlan: TrainingPlanModel

  intervals: TrainingSessionInterval[]
}

export const filterOptions = {
  activityType: Object.values(ActivityTypes),
  status: Object.values(SessionStatus),
  sportType: Object.values(SportTypes),
  phase: Object.values(SessionPhases),
  completed: [
    { key: true, title: '✅' },
    { key: false, title: '❌' },
  ],
}
