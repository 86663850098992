import React, { useEffect } from 'react'
import CloseIcon from '../../svgs/CloseIcon'
import { CloseButton, ModalCard, OverLay } from './style'

type ModalProps = {
  children: React.ReactNode
  onClose: () => void
  width?: string
  enableBodyScroll?: boolean
}

function Modal({ children, onClose, width, enableBodyScroll }: ModalProps) {
  useEffect(() => {
    if (!enableBodyScroll) {
      document.documentElement.style.overflow = 'hidden'
    }
    return () => {
      document.documentElement.style.overflow = 'scroll'
    }
  }, [enableBodyScroll])

  return (
    <>
      <OverLay />
      <ModalCard width={width} absolute={enableBodyScroll}>
        <CloseButton type="button" onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        {children}
      </ModalCard>
    </>
  )
}
export default Modal
