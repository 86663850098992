import React, { useState } from 'react'
import usersApi from '../../../api/users'
import Checkbox from '../../../components/Checkbox'
import InputField from '../../../components/InputField'
import { Heading3Bold, Paragraph2 } from '../../../design-system/typography'
import { Button } from '../../../design-system/utils'
import Roles from '../../../types/Roles'
import User from '../../../types/User'
import { CheckBoxContainer, EditForm } from './style'

type EditUserProps = {
  user: User
  setEditMode: (editMode: boolean) => void
  triggerUpdate: () => void
}

function EditUser({ user, setEditMode, triggerUpdate }: EditUserProps) {
  const [firstName, setFirstName] = useState(user.firstName)
  const [lastName, setLastName] = useState(user.lastName)
  const [role, setRole] = useState(user.role || Roles.USER)

  const submitData = (e) => {
    e.preventDefault()
    usersApi
      .editUser(
        user.id,
        firstName,
        lastName,
        user?.accountStatus,
        role,
        user?.subscription,
        user?.elevated
      )
      .then(() => {
        triggerUpdate()
        setEditMode(false)
      })
  }

  const toggleRole = () => {
    if (role === Roles.ADMIN) {
      setRole(Roles.USER)
    } else {
      setRole(Roles.ADMIN)
    }
  }

  return (
    <>
      <Heading3Bold>
        {user.role === Roles.ADMIN ? 'Edit admin user' : 'Edit user'}
      </Heading3Bold>
      <EditForm onSubmit={submitData}>
        <InputField
          placeholder="First name"
          defaultValue={user.firstName}
          onChangeEvent={setFirstName}
        />
        <InputField
          placeholder="Last name"
          defaultValue={user.lastName}
          onChangeEvent={setLastName}
        />
        <CheckBoxContainer>
          <Checkbox checked={role === Roles.ADMIN} toggleCheck={toggleRole} />
          <Paragraph2>
            Grant user <strong>Admin rights</strong>
          </Paragraph2>
        </CheckBoxContainer>
        <Button type="submit">Save updates</Button>
      </EditForm>
    </>
  )
}

export default EditUser
