import React, { useState, useContext, useCallback } from 'react'
import { Redirect, Link, useLocation } from 'react-router-dom'
import { Button } from '../../design-system/utils'
import { ErrorMessage } from '../../design-system/typography'
import Input from '../InputField'
import AuthFormWrapper from '../AuthFormWrapper'
import authApi from '../../api/auth'
import { AuthContext } from '../../pages/Auth/AuthProvider'

function Login() {
  const { search } = useLocation()

  const { setLoggedIn: setUser } = useContext(AuthContext)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(
    'Something went wrong. Please try again!'
  )
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [loggedIn, setLoggedIn] = useState(false)
  const [isUserAlreadyExist, setIsUserAlreadyExist] = useState(false)

  const fetchUser = useCallback(async () => {
    const { data } = await authApi.getUser()
    setUser(data)
  }, [setUser])

  const login = async (e) => {
    setError(false)
    setErrorMessage('')
    setIsUserAlreadyExist(false)
    e.preventDefault()
    try {
      await authApi.createAccount({
        email,
        password,
        firstName,
        lastName,
        subscription: false,
        reminderNotification: true,
        unitSystem: 'metric',
      })
      setLoggedIn(true)
      await fetchUser()
    } catch (err) {
      if (err?.response?.data === 'email must be unique') {
        setIsUserAlreadyExist(true)
      } else {
        setError(true)
        setErrorMessage('Unable to create account')
      }
    }
  }

  if (loggedIn) {
    return <Redirect to={`/purchase${search}`} />
  }

  return (
    <AuthFormWrapper title="Sign up to start your training">
      <p className="login-wrap">
        Have an account? <Link to={`/login${search}`}>Log In</Link>
      </p>
      <form onSubmit={login}>
        <Input placeholder="First Name" onChangeEvent={setFirstName} />
        <Input placeholder="Last Name" onChangeEvent={setLastName} />

        <Input placeholder="Email" onChangeEvent={setEmail} />
        <Input
          placeholder="Your password"
          type="password"
          onChangeEvent={setPassword}
        />
        {error && <ErrorMessage>{errorMessage}</ErrorMessage>}
        {isUserAlreadyExist && (
          <ErrorMessage>
            You already have an account, please Log In using the{' '}
            <Link to="/login">link here</Link>
          </ErrorMessage>
        )}
        <Button type="submit">Sign Up</Button>
      </form>
    </AuthFormWrapper>
  )
}

export default Login
