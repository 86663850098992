import axios from 'axios'

const getAllRewards = () => {
  return axios.get('/api/reward/list', { withCredentials: true })
}

const deleteReward = (id: string | number) => {
  return axios.delete(`/api/admin/reward/${id}`, { withCredentials: true })
}

const createReward = (
  file,
  title: string,
  shopName: string,
  website: string,
  code: string
) => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('title', title)
  formData.append('shopName', shopName)
  formData.append('website', website)
  formData.append('code', code)
  return axios.post('/api/admin/reward', formData, { withCredentials: true })
}

const editReward = (
  file,
  title: string,
  shopName: string,
  website: string,
  code: string,
  id: string | number
) => {
  const formData = new FormData()
  if (file) {
    formData.append('file', file)
  }

  formData.append('title', title)
  formData.append('shopName', shopName)
  formData.append('website', website)
  formData.append('code', code)
  return axios.put(`/api/admin/reward/${id}`, formData, {
    withCredentials: true,
  })
}

const rewardsApi = {
  getAllRewards,
  deleteReward,
  createReward,
  editReward,
}

export default rewardsApi
