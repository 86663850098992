import styled from 'styled-components'
import { Heading2Bold } from '../../../design-system/typography'

export const TagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${(p) => p.theme.spacing.xs};
  margin-top: ${(p) => p.theme.spacing.xs};

  > div {
    margin: 4px;
  }
`

export const ChannelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${(p) => p.theme.spacing.sm};

  &:last-of-type {
    margin-bottom: 50px;
  }
`

export const ChannelsContainer = styled.div`
  height: 270px;
  position: relative;
  overflow: scroll;
`

export const Title = styled(Heading2Bold)`
  margin-bottom: ${(p) => p.theme.spacing.sm};
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  input {
    width: 380px;
  }
`

export const Fade = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  background-image: linear-gradient(
    0deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0) 100%
  );
  width: 100%;
  height: 100px;
`
