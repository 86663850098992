import React from 'react'
import { ThemeProvider } from 'styled-components'
import { BrowserRouter, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import theme from './design-system/theme'
import GlobalStyle from './design-system/globalStyle'
import './design-system/normalize.css'
import AuthProvider from './pages/Auth/AuthProvider'
import Routes from './Routes'
import 'react-toastify/dist/ReactToastify.css'
import './design-system/index.css'

function App() {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <BrowserRouter>
          <Switch>
            <AuthProvider>
              <ToastContainer />
              <Routes />
            </AuthProvider>
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  )
}

export default App
