import styled from 'styled-components'
import { TextButton } from '../../design-system/utils'
import { Heading5Bold, Paragraph2 } from '../../design-system/typography'

export const TrainingplansGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: ${(p) => p.theme.spacing.xl};
  grid-row-gap: ${(p) => p.theme.spacing.m};
  margin: ${(p) => p.theme.spacing.md} 0;
`

export const DownloadLink = styled(TextButton)`
  color: ${(p) => p.theme.colors.primary};
  font-weight: 700;
  font-size: 14px;
  text-decoration: underline;
  padding: 0;
`

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: ${(p) => p.theme.spacing.md} 0;

  > label {
    margin-left: ${(p) => p.theme.spacing.m};
  }
`

export const FileInput = styled.input`
  display: none;
`

export const UploadMessage = styled.div<{ error?: boolean }>`
  background: ${(p) =>
    p.error ? 'rgba(219, 64, 50, 0.2)' : 'rgba(131, 219, 182, 0.2)'};
  border-radius: 10px;
  padding: 7px 11px;
  width: fit-content;
`

export const UploadMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(p) => p.theme.spacing.md};

  > p {
    margin-right: ${(p) => p.theme.spacing.sm};
  }
`
export const ErrorMessageParagraph = styled(Paragraph2)`
  margin-bottom: 1em;
`

export const ErrorMessage = styled.p`
  margin-left: 1em;
  margin-top: 0.25em;
`

export const FileName = styled(Heading5Bold)`
  margin-bottom: 0.25em;
`
