import React from 'react'

export default function ActionDots() {
  return (
    <svg
      width="3"
      height="13"
      viewBox="0 0 3 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="1.49998" cy="1.4998" r="1.31132" fill="black" />
      <circle cx="1.50002" cy="6.50008" r="1.31112" fill="black" />
      <circle cx="1.5002" cy="11.5001" r="1.31112" fill="black" />
    </svg>
  )
}
