import styled from 'styled-components'

export const SwitchWrapper = styled.label`
  position: relative;
  display: inline-block;
  width: 38px;
  height: 22px;
`

export const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;

  &::before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
`

export const SwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${Slider} {
    background-color: ${(p) => p.theme.colors.primary};
  }

  &:checked + ${Slider}:before {
    transform: translateX(16px);
  }
`
