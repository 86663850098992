import React from 'react'
import {
  ImageWrap,
  TextBlockWrapper,
  ButtonsWrapper,
  ChatWrapper,
  PurchaseWrap,
  PageWrapper,
} from './styles'
import Purchase from '../../assets/images/purchase.png'
import AppStore from '../../assets/images/appStore.png'
import PlayMarket from '../../assets/images/playMarket.png'
import Chat from '../../assets/icons/chat.svg'

const storeLink = 'https://coopah.onelink.me/PFli/coopahwebsite'

const PurchaseSuccess = () => {
  return (
    <PageWrapper>
      <PurchaseWrap>
        <div>
          <ImageWrap src={Purchase} alt="purchase" />
        </div>
        <TextBlockWrapper>
          <h1>Awesome. You’re ready to start training.</h1>
          <p>
            Download the app now to create your training plan. Simply sign in
            with the account details you just created to get access to your 12
            month training plan.
          </p>
          <ButtonsWrapper>
            <a href={storeLink} target="__blank">
              <img src={AppStore} alt="appStore" />
            </a>
            <a href={storeLink} target="__blank">
              <img src={PlayMarket} alt="playMarket" />
            </a>
          </ButtonsWrapper>

          <ChatWrapper>
            <div>
              <img alt="chat" src={Chat} />
              <h6>Chat to our coaches</h6>
            </div>
            <p>
              Don’t forget to chat to our coaches once you’ve created your plan
              and they will be more than happy to help you adjust your plan.
            </p>
          </ChatWrapper>
        </TextBlockWrapper>
      </PurchaseWrap>
    </PageWrapper>
  )
}

export default PurchaseSuccess
