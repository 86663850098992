import styled from 'styled-components'
import { Paragraph2 } from '../../design-system/typography'
import { Line } from '../../design-system/utils'

export const SelectorWrapper = styled.div`
  position: relative;
`

export const CurrentSelection = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  & > svg {
    margin-left: 15px;
  }
`

export const Options = styled.div<{ open: boolean }>`
  box-shadow: ${(p) => p.theme.boxShadow};
  border-radius: ${(p) => p.theme.borderRadius};
  visibility: ${(p) => (p.open ? 'visible' : 'hidden')};
  position: absolute;
  top: 20px;
  left: 0;
  z-index: 3;
  background: ${(p) => p.theme.colors.white};
  transition: 0.1s;
  min-width: 100px;
`

export const OptionLabel = styled(Paragraph2)<{
  isSelected: boolean
  open: boolean
  disabled: boolean
}>`
  padding: ${(p) => p.theme.spacing.xs};
  transition: 0.1s;
  cursor: pointer;
  visibility: ${(p) => (p.open ? 'visible' : 'hidden')};
  font-weight: ${(p) => p.isSelected && '700'};
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
`

export const Divider = styled(Line)`
  &:last-of-type {
    display: none;
  }
`
