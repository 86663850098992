import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { AuthContext } from '../../../pages/Auth/AuthProvider'

const withAuthorization = (WrappedComponent) => {
  const EnhancedComponent = (props) => {
    const { isAuthorized, loggedInUser } = useContext(AuthContext)
    if (!isAuthorized && !loggedInUser) {
      return <Redirect to="/login" />
    }

    // eslint-disable-next-line
    return <WrappedComponent {...props} />
  }

  return EnhancedComponent
}

export default withAuthorization
