import * as React from 'react'
import Box from '@mui/material/Box'
import OutlinedInput from '@mui/material/OutlinedInput'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Chip from '@mui/material/Chip'
import { CheckBoxWrapper } from '../../pages/Chat/ChatEditor/style'

const ITEM_HEIGHT = 45
const ITEM_PADDING_TOP = 4
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

type MultipleSelectChipProps<T> = {
  data: Array<T>
  selectedData: Array<T>
  onChange: (selection: T) => void
  label: string
}

export type MultipleSelectItemProps = {
  key: string
  title: string
  subTitle?: string
}

/**
 * Multi-Select dropdown with Chips from mui.
 *
 * @see https://mui.com/components/selects/
 */
export default function MultipleSelectChip({
  data,
  selectedData,
  onChange,
  label,
}: MultipleSelectChipProps<MultipleSelectItemProps>) {
  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <Select
          labelId="multiple-chip-label"
          id="multiple-chip"
          multiple
          value={selectedData}
          input={<OutlinedInput id="select-multiple-chip" label={label} />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value.key} label={value.title} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
          sx={{
            padding: 0,
            '& .MuiSelect-select': {
              padding: 0,
            },
          }}
        >
          {data.map((item) => (
            <MenuItem
              key={item.key}
              value={item.key}
              sx={{
                padding: 0,
                '&>div': {
                  margin: '10px 20px',
                  width: '100%',
                },
                backgroundColor: selectedData?.some(
                  ({ key }) => key === item?.key
                )
                  ? 'lightgreen'
                  : 'transparent',
                width: '100%',
              }}
            >
              <CheckBoxWrapper>
                {/* eslint-disable-next-line */}
                <div onClick={() => onChange(item)} className={'w-full'}
                >
                  {item.title}
                </div>
              </CheckBoxWrapper>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}
