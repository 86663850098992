import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import trainingplansApi from '../../api/trainingplans'
import OrderByTitle from '../../components/OrderByTitle'
import {
  Heading1Bold,
  Heading5Bold,
  Paragraph2,
} from '../../design-system/typography'
import { Button, Line, PageWrapper } from '../../design-system/utils'
import OrderDirection from '../../types/OrderDirection'
import TrainingPlan from '../../types/TrainingPlan'
import gridTitles from './constants'
import {
  ActionsWrapper,
  DownloadLink,
  FileInput,
  TrainingplansGrid,
  UploadMessage,
  UploadMessageWrapper,
  FileName,
  ErrorMessage,
  ErrorMessageParagraph,
} from './style'
import dateConverter from '../../utils/DateConverter'
import createPlanName from '../../utils/CreatePlanName'
import distanceTokm from '../../utils/DistanceToKm'
import Loader from '../../components/Loader'
import isValidPlanName from '../../utils/ValidatePlanName'
import withAuthorization from '../../components/HOC/withAuthorization'

function TrainingPlans() {
  const [orderBy, setOrderBy] = useState('')
  const [orderDirection, setOrderDirection] = useState(OrderDirection.DESC)
  const [plans, setPlans] = useState<TrainingPlan[]>([])
  const [unauthorized, setUnauthorized] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [success, setSuccess] = useState(false)
  const [newFileNames, setNewFileNames] = useState([])
  const [templatesWithError, setTemplatesWithError] = useState([])

  const setOrder = (orderField: string, direction: OrderDirection) => {
    setOrderBy(orderField)
    setOrderDirection(direction)
    if (direction === OrderDirection.ASC) {
      setPlans((prevPlans) =>
        prevPlans.sort((a, b) => (a[orderField] > b[orderField] ? 1 : -1))
      )
    } else {
      setPlans((prevPlans) =>
        prevPlans.sort((a, b) => (a[orderField] > b[orderField] ? -1 : 1))
      )
    }
  }

  const getPlanTemplates = async () => {
    trainingplansApi
      .getAllTrainingPlans()
      .then((res) => {
        const formattedPlans = res.data.map((plan) => ({
          ...plan,
          name: createPlanName(
            plan.plannedWeeks,
            plan.targetDistance,
            plan?.level
          ),
          formattedDistance: distanceTokm(plan.targetDistance),
        }))
        setPlans(formattedPlans)
      })
      .catch((err) => {
        if (err.response.status === 401 || err.response.status === 403) {
          setUnauthorized(true)
        }
      })
  }

  useEffect(() => {
    getPlanTemplates()
  }, [])

  const downloadTemplate = () => {
    trainingplansApi.getTemplate().then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `trainingplan_template.csv`)
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    })
  }

  const uploadFiles = async (files) => {
    setErrorMessage('')
    setSuccess(false)
    setLoading(true)
    setNewFileNames([])
    setTemplatesWithError([])
    const uploadFile = async (file) => {
      if (!isValidPlanName(file.name)) {
        throw new Error(
          `Incorrect Plan Name: ${file.name}, please rename your file(s) to match the following pattern: trainingplan_20w_marathon_level1.csv`
        )
      }
      await trainingplansApi.uploadPlan(file)
      setNewFileNames((prevState) => [...prevState, file.name])
    }

    const handleUploadError = (file, err) => {
      const uploadError =
        err.response && err.response.data ? err.response.data : err.message
      setTemplatesWithError((prevState) => [
        ...prevState,
        { fileName: file.name, errorMessage: uploadError },
      ])
    }

    const promises = Array.from(files).map((file) =>
      uploadFile(file).catch((err) => handleUploadError(file, err))
    )
    await Promise.allSettled(promises)
    setLoading(false)
    if (templatesWithError.length > 0) {
      setErrorMessage(
        `Invalid file name(s) or upload failure(s). Please rename your file(s) to match the following pattern: trainingplan_20w_marathon_level1.csv \n`
      )
    } else if (newFileNames.length > 0) {
      getPlanTemplates()
      setSuccess(true)
    } else {
      setErrorMessage('Files that were not uploaded: \n')
    }
  }

  const onUpload = (e) => {
    if (!e.target.files?.length) {
      return
    }
    uploadFiles(e.target.files)
  }

  if (unauthorized) {
    return <Redirect to="/login" />
  }

  return (
    <PageWrapper>
      <Heading1Bold>Training plan</Heading1Bold>
      <ActionsWrapper>
        <div>
          <Heading5Bold>Training plan in CSV</Heading5Bold>
          <DownloadLink onClick={downloadTemplate}>
            Download a template
          </DownloadLink>
        </div>
        <Button secondary as="label">
          <FileInput
            type="file"
            accept=".csv"
            onChange={onUpload}
            multiple
            name="file[]"
          />
          {loading ? <Loader /> : 'Upload'}
        </Button>
      </ActionsWrapper>
      {!!templatesWithError.length && (
        <UploadMessageWrapper>
          <UploadMessage error>
            <ErrorMessageParagraph>
              <Heading5Bold>{errorMessage}</Heading5Bold>
            </ErrorMessageParagraph>
            {templatesWithError.map((template) => (
              <React.Fragment key={template.fileName}>
                <FileName>{template.fileName}</FileName>
                <ErrorMessage>{template.errorMessage}</ErrorMessage>
              </React.Fragment>
            ))}
          </UploadMessage>
        </UploadMessageWrapper>
      )}
      {newFileNames?.length ? (
        <UploadMessageWrapper>
          <Paragraph2 style={{ display: 'flex', flexDirection: 'column' }}>
            <Heading5Bold>{newFileNames?.join('\n')}</Heading5Bold>
          </Paragraph2>
          <UploadMessage>
            <Paragraph2>Successfully uploaded</Paragraph2>
          </UploadMessage>
        </UploadMessageWrapper>
      ) : null}
      <Line />
      <TrainingplansGrid>
        {gridTitles.map((title) => (
          <OrderByTitle
            title={title.title}
            field={title.field}
            isChosen={title.field === orderBy}
            chosenDirection={orderDirection}
            setOrder={setOrder}
            key={title.field}
          />
        ))}
        {plans.map((plan) => (
          <React.Fragment key={plan.id}>
            <Paragraph2>{plan.name ? plan.name : '-'}</Paragraph2>
            <Paragraph2>{plan.free ? 'Free' : 'Member'}</Paragraph2>
            <Paragraph2>
              {plan.createdAt ? dateConverter(plan.createdAt) : '-'}
            </Paragraph2>
            <Paragraph2>
              {plan.formattedDistance ? `${plan.formattedDistance} km` : '-'}
            </Paragraph2>
            <Paragraph2>
              {plan.plannedWeeks ? plan.plannedWeeks : '-'}
            </Paragraph2>
            <Paragraph2>{plan?.level || '-'}</Paragraph2>
          </React.Fragment>
        ))}
      </TrainingplansGrid>
      {/* @deprecated */}
      {/* {overwriteFile && (
        <OverWriteModal
          onClose={() => setOverwriteFile(null)}
          onUploadFile={() => uploadFiles(overwriteFile)}
        />
      )} */}
    </PageWrapper>
  )
}

export default withAuthorization(TrainingPlans)
