import React from 'react'
import { Paragraph2, Paragraph2Bold } from '../../../design-system/typography'
import SessionRowData from '../SessionRowData'
import { Title, Wrapper } from './style'

type UserSessionsProps = {
  sessions: {
    date: string
    title: string
    id: number
    activityType: string
  }[]
}

export default function UserSession({ sessions }: UserSessionsProps) {
  return (
    <>
      <Wrapper>
        <Title>
          <Paragraph2Bold>Sessions for next 7 days</Paragraph2Bold>
        </Title>
        {sessions.length > 0 ? (
          sessions.map(({ id, title, activityType, date }) => (
            <SessionRowData
              title={title}
              activityType={activityType}
              date={date}
              id={id}
              key={id}
            />
          ))
        ) : (
          <Paragraph2>No sessions yet</Paragraph2>
        )}
      </Wrapper>
    </>
  )
}
