import React from 'react'
import { Paragraph2Bold } from '../../design-system/typography'
import OrderArrow from '../../svgs/OrderArrow'
import OrderDirection from '../../types/OrderDirection'
import OrderButton from './OrderButton'
import Container from './style'

type OrderByTitleProps = {
  title: string
  field: string
  isChosen: boolean
  chosenDirection: OrderDirection
  setOrder: (orderBy: string, direction: OrderDirection) => void
}

function OrderByTitle({
  title,
  field,
  isChosen,
  setOrder,
  chosenDirection,
}: OrderByTitleProps) {
  return (
    <Container>
      <OrderButton
        field={field}
        isChosen={isChosen}
        setOrder={setOrder}
        chosenDirection={chosenDirection}
      />
      <Paragraph2Bold>{title}</Paragraph2Bold>
    </Container>
  )
}

export default OrderByTitle
