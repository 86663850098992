const dateConverter = (dateToConvert: string) => {
  const date = new Date(dateToConvert)
  const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date)
  const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date)
  const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date)
  return `${day} ${month} ${year}`
}

export const dateToMonthYear = (dateToConvert: string) => {
  const date = new Date(dateToConvert)
  const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date)
  const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date)
  return `${month} ${year}`
}

export const dateToMonth = (dateToConvert: string) => {
  const date = new Date(dateToConvert)
  const month = new Intl.DateTimeFormat('en', { month: 'long' }).format(date)
  return `${month}`
}

export default dateConverter
