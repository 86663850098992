import React, { useContext, useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { Button } from '../../../design-system/utils'
import { ErrorMessage, LinkOrange } from '../../../design-system/typography'
import InputField from '../../../components/InputField'
import AuthFormWrapper from '../../../components/AuthFormWrapper'
import authApi from '../../../api/auth'
import { AuthContext } from '../AuthProvider'

function Login() {
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(
    'Something went wrong. Please try again!'
  )
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loggedIn, setLoggedIn] = useState(false)

  const { search } = useLocation()

  const {
    isAuthorized,
    setLoggedIn: setLoggedInProvider,
    loggedInUser,
  } = useContext(AuthContext)

  const login = async (e) => {
    e.preventDefault()
    setError(false)

    try {
      const { data } = await authApi.loginUser(email, password)
      if (!data) {
        throw new Error()
      }
      setLoggedInProvider(null)
      setLoggedIn(true)
    } catch (err) {
      setError(true)
      setErrorMessage('Invalid credentials. Please try again!')
    }
  }
  if ((loggedIn || isAuthorized) && !loggedInUser) {
    return null
  }
  if ((loggedIn || isAuthorized) && loggedInUser?.role === 'admin') {
    return <Redirect to="/" />
  }
  if (
    (loggedIn || isAuthorized) &&
    loggedInUser?.role === 'user' &&
    loggedInUser?.accountStatus === 'default'
  ) {
    return <Redirect to={`/purchase${search}`} />
  }

  if (
    (loggedIn || isAuthorized) &&
    loggedInUser?.role === 'user' &&
    loggedInUser?.accountStatus !== 'default'
  ) {
    return <Redirect to="/purchase-failed" />
  }
  return (
    <AuthFormWrapper title="Login to your Coopah account">
      <form onSubmit={login}>
        <InputField placeholder="Your email address" onChangeEvent={setEmail} />
        <InputField
          placeholder="Your password"
          type="password"
          onChangeEvent={setPassword}
        />
        <div style={{ textAlign: 'right' }}>
          <LinkOrange href="/reset">Reset password</LinkOrange>
        </div>
        {error && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <Button type="submit">Log in</Button>
      </form>
    </AuthFormWrapper>
  )
}

export default Login
