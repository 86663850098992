import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import moment from 'moment'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import copy from 'copy-to-clipboard'
import { toast } from 'react-toastify'
import couponApi from '../../../api/coupons'
import CouponApiData from '../../../types/CouponApiData'
import { entitlementDurationOptions } from '../CouponEditor/CouponEditor'

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
})

function Row(props) {
  const { row, onUpdate } = props
  const [open, setOpen] = React.useState(false)
  const classes = useRowStyles()

  const deleteCoupon = (id) => {
    couponApi.deleteCoupon(id).then(() => {
      toast.success('Coupon deleted')
      onUpdate()
    })
  }

  const copyCouponsOfGroup = () => {
    copy(row.coupons.map((c) => c?.code).join('\n'))
    toast.success('Coupons copied to clipboard')
  }
  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <IconButton onClick={() => copyCouponsOfGroup()}>
            <ContentCopyOutlinedIcon />
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.group}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Actions</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Code</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Max Redemptions</TableCell>
                    <TableCell>Time Redeemed</TableCell>
                    <TableCell>Apple Coupon</TableCell>
                    <TableCell>Google Coupon</TableCell>
                    <TableCell>Revenuecat Entitlement Duration</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!row?.coupons?.length && (
                    <TableRow>
                      <TableCell align="center" colSpan={8}>
                        No coupons found
                      </TableCell>
                    </TableRow>
                  )}
                  {row?.coupons?.map((coupon) => (
                    <TableRow key={coupon?.id}>
                      <TableCell component="th" scope="row">
                        <IconButton onClick={() => deleteCoupon(coupon?.id)}>
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            copy(coupon?.code)
                            toast.success('Copied!')
                          }}
                        >
                          <ContentCopyOutlinedIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell>{coupon?.title}</TableCell>
                      <TableCell>{coupon?.code}</TableCell>
                      <TableCell>
                        {moment(coupon?.startDateTime).format(
                          'YYYY-MM-DD HH:MM:SS'
                        )}
                      </TableCell>
                      <TableCell>
                        {coupon?.endDateTime
                          ? moment(coupon?.endDateTime).format(
                              'YYYY-MM-DD HH:MM:SS'
                            )
                          : 'N/A'}
                      </TableCell>
                      <TableCell>{coupon?.status || 'N/A'}</TableCell>
                      <TableCell>{coupon?.maxRedemptions || 'N/A'}</TableCell>
                      <TableCell>{coupon?.timesRedeemed || 'N/A'}</TableCell>
                      <TableCell>{coupon?.appleCouponCode || 'N/A'}</TableCell>
                      <TableCell>{coupon?.googleCouponCode || 'N/A'}</TableCell>
                      <TableCell>
                        {entitlementDurationOptions?.find(
                          (i) => i?.field === coupon?.entitlementDuration
                        )?.label || 'N/A'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default function CollapsibleTable({ coupons, onUpdate, search }) {
  const formattedCoupons = useMemo(() => {
    if (!coupons) return []
    return Object.entries(coupons).map(([group, couponsList]) => {
      const list = couponsList as Array<CouponApiData>
      return {
        group,
        coupons: list?.filter(
          (c) =>
            c.code.toLowerCase().includes(search.toLowerCase()) ||
            c.title.toLowerCase().includes(search.toLowerCase())
        ),
      }
    })
  }, [coupons, search])

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />

            <TableCell>Copy coupons</TableCell>
            <TableCell width="90%">Group</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formattedCoupons.map((row) => (
            <Row key={row.group} row={row} onUpdate={onUpdate} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
