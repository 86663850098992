import styled from 'styled-components'

export const Options = styled.div<{ open: boolean }>`
  min-width: 130px;
  background: ${(p) => p.theme.colors.white};
  box-shadow: ${(p) => p.theme.boxShadow};
  border-radius: ${(p) => p.theme.borderRadius};
  position: absolute;
  top: ${(p) => p.theme.spacing.sm};
  right: -20px;
  visibility: ${(p) => (p.open ? 'visible' : 'hidden')};
  transition: 0.1s;

  > p {
    padding: 12px;
    cursor: pointer;
  }
`

export const Wrapper = styled.div`
  position: relative;
`
