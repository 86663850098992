import React, { createContext, useCallback, useEffect, useState } from 'react'
import api from '../../../api/auth'

export const AuthContext = createContext(null)

const AuthProvider = ({ children }) => {
  const [loggedInUser, setLoggedInUser] = useState(null)
  const [isAuthorized, setIsAuthorized] = useState(
    sessionStorage.getItem('isAuthorized') === 'true'
  )

  const setLoggedIn = (user) => {
    setLoggedInUser(user)
    setIsAuthorized(true)
    sessionStorage.setItem('isAuthorized', 'true')
  }

  const logout = () => {
    sessionStorage.setItem('isAuthorized', 'false')
    setLoggedInUser(null)
    setIsAuthorized(false)
  }

  const fetchUser = useCallback(async () => {
    try {
      const { data } = await api.getUser()
      setLoggedInUser(data)
    } catch (error) {
      setLoggedInUser(null)
      setIsAuthorized(false)
    }
  }, [])

  useEffect(() => {
    if (isAuthorized && !loggedInUser) {
      fetchUser()
    }
  }, [loggedInUser, fetchUser, isAuthorized])
  return (
    <AuthContext.Provider
      value={{ loggedInUser, setLoggedIn, isAuthorized, logout }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
