import React, { useState } from 'react'
import { Input } from '@material-ui/core'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { Wrapper } from './style'
import { LabelBold } from '../../design-system/typography'
import { Line } from '../../design-system/utils'

type DatePickerProps = {
  label: string
  setDate: (date) => void
  selectedDate: Date
  minDate?: Date
}

function DatePicker({
  label,
  setDate,
  selectedDate,
  minDate,
}: DatePickerProps) {
  const [isOpen, setIsOpen] = useState(false)

  const renderInput = (props): any => (
    <Input
      type="text"
      inputRef={props.inputRef}
      inputProps={props.inputProps}
      value={props.value}
      onClick={props.onClick}
      onChange={props.onChange}
      endAdornment={props.InputProps?.endAdornment}
      disableUnderline
      style={{ fontFamily: 'Century Gothic,sans-serif' }}
    />
  )

  return (
    <Wrapper>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <LabelBold>{label}</LabelBold>
        <DesktopDatePicker
          open={isOpen}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          value={selectedDate}
          minDate={minDate}
          onChange={(newValue) => {
            setDate(newValue)
          }}
          renderInput={renderInput}
        />
        <Line />
      </LocalizationProvider>
    </Wrapper>
  )
}
export default DatePicker
