import styled from 'styled-components'
import { Paragraph2 } from '../../../design-system/typography'
import { Line } from '../../../design-system/utils'

export const Wrapper = styled.div`
  width: 600px;
  margin-top: ${(p) => p.theme.spacing.m};
  padding-right: ${(p) => p.theme.spacing.m};
  position: relative;
`

export const Description = styled(Paragraph2)`
  margin: ${(p) => `${p.theme.spacing.m} 0 ${p.theme.spacing.xs}`};
  ${(p) => p.noDescription && `color: ${p.theme.colors.grays[4]};`}
`

export const AutoAssigned = styled.div`
  display: flex;
  align-items: center;
  > p {
    margin-left: 5px;
    color: ${(p) => p.theme.colors.grays[4]};
  }
`

export const Divider = styled(Line)`
  margin-top: ${(p) => p.theme.spacing.m};
`

export const ActionsWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`
