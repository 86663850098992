import React, { useState } from 'react'
import { ErrorMessage, LabelBold } from '../../design-system/typography'
import ImageIcon from '../../svgs/ImageIcon'
import {
  FileInput,
  ImagePlaceholder,
  ImagePreview,
  UploadButton,
} from './style'

type ImageUploaderProps = {
  defaultImageUrl?: string
  setImage: (img) => void
  requiredError?: boolean
}

function ImageUploader({
  defaultImageUrl,
  setImage,
  requiredError,
}: ImageUploaderProps) {
  const [preview, setPreview] = useState(defaultImageUrl)

  const uploadImage = (e) => {
    const file = e.target.files[0]
    if (!file) {
      setPreview(null)
    } else {
      const url = URL.createObjectURL(file)
      setPreview(url)
      setImage(file)
    }
  }

  return (
    <div>
      <LabelBold>Add thumbnail image</LabelBold>
      {preview ? (
        <>
          <ImagePreview alt="Thumbnail image" src={preview} />
          <UploadButton>
            <FileInput type="file" accept="image/*" onChange={uploadImage} />
            Upload new
          </UploadButton>
        </>
      ) : (
        <>
          <ImagePlaceholder>
            <ImageIcon />
          </ImagePlaceholder>
          <UploadButton type="button">
            <FileInput type="file" accept="image/*" onChange={uploadImage} />
            Upload image
          </UploadButton>
          {requiredError && <ErrorMessage>Required</ErrorMessage>}
        </>
      )}
    </div>
  )
}
export default ImageUploader
