import styled from 'styled-components'
import { Paragraph2 } from '../../design-system/typography'
import { Line } from '../../design-system/utils'

export const Wrapper = styled.div`
  margin: ${(p) => p.theme.spacing.xs} 0;
  position: relative;
  width: 150px;
`

export const Selector = styled.button`
  outline: none;
  background: none;
  padding: ${(p) => p.theme.spacing.xs} 0;
  border: none;
  width: 100%;
  border-bottom: 1px solid ${(p) => p.theme.colors.grays[1]};
  font-size: 14px;
  cursor: pointer;
  text-align: left;
`

export const IconWrapper = styled.div`
  position: absolute;
  right: 5px;
  top: 24px;
`

export const Options = styled.div<{ visible: boolean }>`
  box-shadow: ${(p) => p.theme.boxShadow};
  border-radius: ${(p) => p.theme.borderRadius};
  visibility: ${(p) => (p.visible ? 'visible' : 'hidden')};
  position: absolute;
  top: 45px;
  left: 0;
  z-index: 3;
  background: ${(p) => p.theme.colors.white};
  transition: 0.1s;
  width: 150px;
  max-height: 100px;
  overflow: scroll;
`

export const Divider = styled(Line)`
  &:last-of-type {
    display: none;
  }
`

export const OptionLabel = styled(Paragraph2)<{
  isSelected: boolean
  visible: boolean
}>`
  padding: ${(p) => p.theme.spacing.xs};
  transition: 0.1s;
  cursor: pointer;
  visibility: ${(p) => (p.visible ? 'visible' : 'hidden')};
  font-weight: ${(p) => p.isSelected && '700'};
`
