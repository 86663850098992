import styled from 'styled-components'

export const EditForm = styled.form`
  margin-top: ${(p) => p.theme.spacing.m};

  > div {
    margin-bottom: ${(p) => p.theme.spacing.sm};
  }

  > button {
    margin: ${(p) => p.theme.spacing.m} 0;
  }
`

export const CheckBoxContainer = styled.div`
  display: flex;
  max-width: 250px;
  align-items: flex-start;

  > p {
    margin-left: 15px;
  }
`
