import React from 'react'

export default function EmptyCheckbox() {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 0.713867H2.5C1.11929 0.713867 0 1.83316 0 3.21387V16.2139C0 17.5946 1.11929 18.7139 2.5 18.7139H15.5C16.8807 18.7139 18 17.5946 18 16.2139V3.21387C18 1.83316 16.8807 0.713867 15.5 0.713867ZM1 3.21387C1 2.38544 1.67157 1.71387 2.5 1.71387H15.5C16.3284 1.71387 17 2.38544 17 3.21387V16.2139C17 17.0423 16.3284 17.7139 15.5 17.7139H2.5C1.67157 17.7139 1 17.0423 1 16.2139V3.21387Z"
        fill="#A6A6A6"
      />
    </svg>
  )
}
