import React from 'react'
import { LabelSmall, LabelSmallBold } from '../../../design-system/typography'
import { InfoLabel, LabelContainer, TextContainer, Wrapper } from './style'

type SessionRowDataProps = {
  title: string
  id: number
  activityType: string
  date: string
}

function SessionRowData({
  title,
  date,
  activityType,
  id,
}: SessionRowDataProps) {
  return (
    <Wrapper>
      <TextContainer>
        <LabelSmallBold>{title}</LabelSmallBold>
        <LabelContainer>
          <InfoLabel>
            <LabelSmall>Date: </LabelSmall>
            <LabelSmallBold>{date}</LabelSmallBold>
          </InfoLabel>
          <InfoLabel>
            <LabelSmall>Activity Type: </LabelSmall>
            <LabelSmallBold> {activityType}</LabelSmallBold>
          </InfoLabel>
          <InfoLabel>
            <LabelSmall>Session id: </LabelSmall>
            <LabelSmallBold>{id}</LabelSmallBold>
          </InfoLabel>
        </LabelContainer>
      </TextContainer>
    </Wrapper>
  )
}
export default SessionRowData
