import React, { useState } from 'react'
import { ErrorMessage, LabelBold } from '../../design-system/typography'
import ImageIcon from '../../svgs/ImageIcon'
import CloseIcon from '../../svgs/CloseIcon'
import { Divider } from '../../pages/Club/ClubCard/style'

import {
  FileInput,
  ImagePlaceholder,
  GalleryImagePreview,
  UploadButton,
  GalleryImageWrap,
  GalleryImageItem,
} from './style'

type GalleryImage = {
  imageUrl: string
}

type ImageUploaderProps = {
  defaultGalleryUrls?: GalleryImage[]
  setGallery: (img) => void
  setImagesToDelete: (url) => void
  requiredError?: boolean
}

function ImageUploader({
  defaultGalleryUrls,
  setGallery,
  requiredError,
  setImagesToDelete,
}: ImageUploaderProps) {
  const [preview, setPreview] = useState(
    defaultGalleryUrls.map(({ imageUrl }) => imageUrl)
  )

  const uploadImage = (e) => {
    const { files } = e.target
    const images = [...files].map((file) => URL.createObjectURL(file))
    setPreview((prevState) => [
      ...prevState.filter((url) => url.includes('https://')),
      ...images,
    ])
    setGallery(files)
  }

  const removeImage = (url) => {
    setPreview((prevState) => prevState.filter((item) => item !== url))
    setImagesToDelete((prevState) => [...prevState, url])
  }

  return (
    <div>
      <Divider />
      <LabelBold>Add club gallery</LabelBold>
      {preview ? (
        <>
          <GalleryImageWrap>
            {preview.map((url) => (
              <GalleryImageItem key={url}>
                {url.includes('https://') && (
                  <button onClick={() => removeImage(url)} type="button">
                    <CloseIcon />
                  </button>
                )}
                <GalleryImagePreview alt="Thumbnail image" src={url} />
              </GalleryImageItem>
            ))}
          </GalleryImageWrap>
          <UploadButton>
            <FileInput
              type="file"
              accept="image/*"
              multiple="multiple"
              onChange={uploadImage}
            />
            Upload new
          </UploadButton>
          {preview.length > 6 && (
            <ErrorMessage>
              You are not able to upload more than 6 images
            </ErrorMessage>
          )}
        </>
      ) : (
        <>
          <ImagePlaceholder>
            <ImageIcon />
          </ImagePlaceholder>
          <UploadButton type="button">
            <FileInput
              type="file"
              accept="image/*"
              multiple="multiple"
              onChange={uploadImage}
            />
            Upload image
          </UploadButton>
          {requiredError && <ErrorMessage>Required</ErrorMessage>}
          {preview.length > 6 && (
            <ErrorMessage>
              You are not able to upload more than 6 images
            </ErrorMessage>
          )}
        </>
      )}
    </div>
  )
}
export default ImageUploader
