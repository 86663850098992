import React, { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Button, PageWrapper } from '../../design-system/utils'
import {
  UserGrid,
  Divider,
  GridTitle,
  EmptyGridMessage,
  FilterWrapper,
  HeadingWrapper,
  GridWrapper,
} from './style'
import { Heading1Bold, Paragraph2 } from '../../design-system/typography'
import trainingplansApi from '../../api/trainingplans'
import withAuthorization from '../../components/HOC/withAuthorization'

const UserTrainingPlans = () => {
  const [plans, setPlans] = React.useState([])

  const { userId } = useParams()
  const fetchUserTrainingPlans = useCallback(async () => {
    const resp = await trainingplansApi.getUserTrainingPlans(+userId)
  }, [userId])

  useEffect(() => {
    fetchUserTrainingPlans()
  }, [fetchUserTrainingPlans, userId])

  const getGrid = () => {
    if (plans.length === 0) {
      return (
        <EmptyGridMessage>No users match the selected filter.</EmptyGridMessage>
      )
    }
    return null
  }

  return (
    <PageWrapper>
      <HeadingWrapper>
        <Heading1Bold>Users</Heading1Bold>

        <FilterWrapper>
          {/* <Paragraph2>Filter by role:</Paragraph2> */}
          {/* <DropdownSelector
        currentSelection={roleFilter}
        options={roles}
        onSelect={filterByRole}
        boldTitle
      /> */}
          {/* <SearchField placeholder="Search user" onSearch={setSearchWord} /> */}
        </FilterWrapper>
      </HeadingWrapper>
      {/* <GridWrapper id="userGrid">{getGrid()}</GridWrapper> */}
    </PageWrapper>
  )
}

export default withAuthorization(UserTrainingPlans)
