import React, { useEffect, useState } from 'react'
import { HexColorPicker, HexColorInput } from 'react-colorful'
import clubApi from '../../../api/clubs'
import ImageUploader from '../../../components/ImageUploader'
import GalleryUploader from '../../../components/GalleryUploader'
import InputField from '../../../components/InputField'
import Modal from '../../../components/Modal'
import Switch from '../../../components/Switch'
import ClubSocialLinks from './ClubSocialLinks'
import HeaderImageUploader from '../HeaderImageUploader'
import {
  Heading2Bold,
  Paragraph2,
  LabelBold,
} from '../../../design-system/typography'
import { Button, TextButton } from '../../../design-system/utils'
import {
  Form,
  InputFields,
  Error,
  SwitchWrapper,
  SwitchLabel,
  ImagesWrap,
  ColorPickerWrap,
  RightSideWrap,
} from './style'
import isValidUUID from '../../../utils/validateUUID'

type ClubEditorProps = {
  closeModal: () => void
  onUpdate: () => void
  defaultClub?
}

function ClubEditor({ closeModal, onUpdate, defaultClub }: ClubEditorProps) {
  const [error, setError] = useState(null)
  const [file, setFile] = useState(null)
  const [headerImage, setHeaderImage] = useState(null)
  const [gallery, setGallery] = useState([])
  const [imagesToDelete, setImagesToDelete] = useState([])
  const [name, setName] = useState(defaultClub?.name || '')
  const [description, setDescription] = useState(defaultClub?.description || '')
  const [clubCode, setClubCode] = useState(defaultClub?.clubCode || '')
  const [appStoreSubscriptionId, setAppStoreSubscriptionId] = useState(
    defaultClub?.appStoreSubscriptionId || ''
  )
  const [playStoreSubscriptionId, setPlayStoreSubscriptionId] = useState(
    defaultClub?.playStoreSubscriptionId || ''
  )
  const [elevated, setElevated] = useState(defaultClub?.elevated || false)
  const [clubColor, setClubColor] = useState(
    defaultClub?.clubColor || '#F27C38'
  )
  const [externalLinks, setExternalLinks] = useState(
    defaultClub?.externalLinks || []
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const formatExternalLinks = () => {
    const externalLinksToCreate = externalLinks
      .filter(({ id }) => !isValidUUID(id))
      .map(({ id, ...rest }) => rest)
    const externalLinksToDelete = externalLinks.filter(
      (item) => isValidUUID(item?.id) && !item?.link
    )

    const externalLinksToUpdate = externalLinks.filter(
      (item) => isValidUUID(item?.id) && item?.link
    )

    return {
      externalLinksToCreate,
      externalLinksToDelete,
      externalLinksToUpdate,
    }
  }
  const sendData = () => {
    if (defaultClub) {
      return clubApi.updateClub({
        id: defaultClub.id,
        file,
        name,
        headerImage,
        description,
        clubCode,
        appStoreSubscriptionId,
        playStoreSubscriptionId,
        elevated,
        clubColor,
        imagesToDelete,
        gallery,
        ...formatExternalLinks(),
      })
    }

    return clubApi.createClub({
      file,
      name,
      headerImage,
      description,
      clubCode,
      appStoreSubscriptionId,
      playStoreSubscriptionId,
      elevated,
      clubColor,
      gallery,
      ...formatExternalLinks(),
    })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    sendData()
      .then(() => {
        onUpdate()
        closeModal()
        setImagesToDelete([])
        setGallery([])
      })
      .catch((err) => {
        if (err.response.status === 422) {
          setError(
            'Cannot save changes. Please make that all required fields are filled.'
          )
        } else {
          setError(
            err.response?.data || 'Something went wrong. Please try again.'
          )
        }
      })
  }

  return (
    <Modal onClose={closeModal} width="695px" enableBodyScroll>
      <Heading2Bold>{defaultClub ? 'Edit Club' : 'Create a Club'}</Heading2Bold>
      <Form onSubmit={onSubmit}>
        <InputFields>
          <ImagesWrap>
            <ImageUploader
              setImage={setFile}
              requiredError={error && !file}
              defaultImageUrl={defaultClub?.imageUrl}
            />
            <HeaderImageUploader
              setImage={setHeaderImage}
              requiredError={error && !headerImage}
              defaultImageUrl={defaultClub?.headerImageUrl}
            />
            <GalleryUploader
              defaultGalleryUrls={defaultClub?.galleryImages || []}
              setGallery={setGallery}
              requiredError={error && !gallery?.length}
              setImagesToDelete={setImagesToDelete}
            />
          </ImagesWrap>
          <RightSideWrap>
            <InputField
              placeholder="Title"
              onChangeEvent={setName}
              errorMessage="Required"
              error={error && name === ''}
              defaultValue={name}
            />
            <InputField
              placeholder="Description"
              onChangeEvent={setDescription}
              errorMessage="Required"
              error={error && description === ''}
              defaultValue={description}
            />
            <InputField
              placeholder="Club code (e.g., COP-016)"
              onChangeEvent={setClubCode}
              error={error && clubCode === ''}
              defaultValue={clubCode}
              errorMessage="Required"
            />

            <InputField
              placeholder="Apple Store Subscription Id"
              onChangeEvent={setAppStoreSubscriptionId}
              error={error && appStoreSubscriptionId === ''}
              defaultValue={appStoreSubscriptionId}
            />
            <InputField
              placeholder="Google Play Store Subscription Id"
              onChangeEvent={setPlayStoreSubscriptionId}
              error={error && playStoreSubscriptionId === ''}
              defaultValue={playStoreSubscriptionId}
            />

            <LabelBold>Choose club color</LabelBold>
            <ColorPickerWrap>
              <HexColorPicker color={clubColor} onChange={setClubColor} />
              <HexColorInput
                color={clubColor}
                onChange={setClubColor}
                prefixed
              />
            </ColorPickerWrap>
            <SwitchWrapper>
              <Switch
                checked={elevated}
                onChange={() =>
                  setElevated((previousElevated) => !previousElevated)
                }
              />
              <div>
                <Paragraph2>Donated</Paragraph2>
                <SwitchLabel>
                  Members of the club will have free subscription.
                </SwitchLabel>
              </div>
            </SwitchWrapper>
            <ClubSocialLinks
              externalLinks={externalLinks}
              setExternalLinks={setExternalLinks}
            />
          </RightSideWrap>
        </InputFields>
        {error && <Error>{error}</Error>}
        <Button secondary type="submit">
          {defaultClub ? 'Save Changes' : 'Create Club'}
        </Button>
        <TextButton type="button" onClick={closeModal}>
          Cancel
        </TextButton>
      </Form>
    </Modal>
  )
}

export default ClubEditor
