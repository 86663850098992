import React from 'react'

export default function FilledCheckBox() {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 0.713867H15.5C16.8807 0.713867 18 1.83316 18 3.21387V16.2139C18 17.5946 16.8807 18.7139 15.5 18.7139H2.5C1.11929 18.7139 0 17.5946 0 16.2139V3.21387C0 1.83316 1.11929 0.713867 2.5 0.713867ZM2.5 1.71387C1.67157 1.71387 1 2.38544 1 3.21387V16.2139C1 17.0423 1.67157 17.7139 2.5 17.7139H15.5C16.3284 17.7139 17 17.0423 17 16.2139V3.21387C17 2.38544 16.3284 1.71387 15.5 1.71387H2.5ZM12.8536 6.86031C12.6583 6.66505 12.3417 6.66505 12.1464 6.86031L7.5 11.5068L5.85355 9.86031C5.65829 9.66505 5.34171 9.66505 5.14645 9.86031C4.95118 10.0556 4.95118 10.3722 5.14645 10.5674L7.14645 12.5674C7.34171 12.7627 7.65829 12.7627 7.85355 12.5674L12.8536 7.56742C13.0488 7.37216 13.0488 7.05558 12.8536 6.86031Z"
        fill="#F27C38"
      />
    </svg>
  )
}
