const distanceTokm = (distance) => {
  switch (distance) {
    case '5km':
      return 5
    case '10km':
      return 10
    case 'halfmarathon':
      return 21
    case 'marathon':
      return 42
    case 'ultra_preparation':
      return 0
    case 'ultra_50km':
      return 50
    case 'ultra_100km':
      return 50
    case 'coopah_100km':
      return 0
    default:
      return 0
  }
}

export default distanceTokm
