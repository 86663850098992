import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import TrainingSessionsTable from './TrainingSessionsTable'
import withAuthorization from '../../components/HOC/withAuthorization'
import usersApi from '../../api/users'

const sources = [
  { id: 'cache', title: 'Cache/Filtered Sessions' },
  { id: 'database', title: 'Database' },
]

const TrainingSessions = () => {
  const { userId, planId } = useParams()
  const [data, setData] = useState([])
  const [source, setSource] = useState(sources[0]?.id)

  const fetchSessions = useCallback(async () => {
    try {
      const response = await usersApi.getUserActivePlan({
        userId,
        planId,
        source,
      })
      setData(
        response?.data?.map((s) => ({
          ...s,
          subRow: [...s?.intervals],
        }))
      )
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }, [userId, planId, source])

  useEffect(() => {
    fetchSessions()
  }, [fetchSessions, source])
  return (
    <div>
      <div className="mx-auto w-1/3">
        <p className="text-base font-semibold text-gray-900">Data Source</p>
        <p className="text-sm text-gray-500">Where to get data from?</p>
        <fieldset className="mt-4">
          <legend className="sr-only">Data Source</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
            {sources.map((s) => (
              <div key={s.id} className="flex items-center">
                <input
                  id={s.id}
                  name="notification-method"
                  type="radio"
                  defaultChecked={s.id === 'cache'}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  onClick={() => setSource(s.id)}
                />
                <label
                  htmlFor={s.id}
                  className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                >
                  {s.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
      </div>
      <TrainingSessionsTable sessions={data} />
    </div>
  )
}

export default withAuthorization(TrainingSessions)
