import LabeledField from '../../types/LabeledField'
import Roles from '../../types/Roles'

export const gridTitles: Array<LabeledField> = [
  { label: 'Name', field: 'firstName' },
  { label: 'E-mail address', field: 'email' },
  { label: 'Registration date', field: 'createdAt' },
  { label: 'Modified', field: 'updatedAt' },

  { label: 'Training plan', field: 'trainingplan' },
  {
    label: 'Target race date',
    field: 'targetrace',
  },
  { label: 'Club', field: 'club' },
  { label: 'Status', field: 'accountStatus' },
]

export const roles: Array<LabeledField> = [
  {
    label: 'All users',
    field: Roles.ALL,
  },
  {
    label: 'Users',
    field: Roles.USER,
  },
  {
    label: 'Admins',
    field: Roles.ADMIN,
  },
]

export const donatedOptions = [
  { label: 'Yes', field: 'true' },
  { label: 'No', field: 'false' },
]

export const accountStatusOptions = [
  { label: 'Subscribed', field: 'subscribed', disabled: true },
  { label: 'Donated', field: 'donated' },
  { label: 'Limited', field: 'limited' },
  { label: 'Default', field: 'default' },
]
