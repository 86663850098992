import styled from 'styled-components'
import { Link } from 'react-router-dom'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${(p) => p.theme.spacing.l};

  & > div {
    margin-bottom: ${(p) => p.theme.spacing.m};
  }

  & > img {
    max-width: 300px;
  }

  & > h4 {
    margin-top: ${(p) => p.theme.spacing.m};
    margin-bottom: ${(p) => p.theme.spacing.m};
  }

  & > p {
    text-align: center;
    max-width: 280px;
    margin-bottom: ${(p) => p.theme.spacing.sm};
  }

  & > form {
    max-width: 366px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & > button,
  a {
    margin-bottom: ${(p) => p.theme.spacing.m};
  }

  & > form > button {
    margin: ${(p) => p.theme.spacing.md} 0;
  }

  .login-wrap {
    margin-bottom: ${(p) => p.theme.spacing.sm};
    a {
      color: #ff5700;
      text-decoration: none;
    }
  }
`

export const LoginLink = styled(Link)`
  font-size: 16px;
  font-family: Circular Std;
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
  color: #000;
  display: flex;
  align-items: center;
  margin-bottom: 26px;
  img {
    margin-right: 5px;
  }
`

export const MarketLink = styled.a`
  font-size: 16px;
  font-family: Circular Std;
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
  color: #000;
  display: flex;
  align-items: center;
  margin-bottom: 26px;
  img {
    margin-right: 5px;
  }
`

export default Wrapper
