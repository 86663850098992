import React from 'react'
import { FooterWrap, LinksWrap, IconsWrap } from './styles'
import Logo from '../../assets/images/logo.png'
import Instagram from '../../assets/icons/instagram.svg'
import Facebook from '../../assets/icons/facebook.svg'
import Linkedin from '../../assets/icons/linkedin.svg'
import Tiktok from '../../assets/icons/tiktok.svg'

const links = [
  {
    icon: Instagram,
    url: 'https://www.instagram.com/coopahruncoach/',
  },
  {
    icon: Facebook,
    url: 'https://www.facebook.com/coopahrunning',
  },
  {
    icon: Linkedin,
    url: 'https://uk.linkedin.com/company/coopah',
  },
  {
    icon: Tiktok,
    url: 'https://www.tiktok.com/@coopah.running',
  },
]

const Footer = () => {
  return (
    <FooterWrap>
      <img src={Logo} alt="Logo" className="logo" />
      <a className="email" target="__blank" href="mailto:run@coopah.com">
        run@coopah.com
      </a>

      <IconsWrap>
        {links?.map((item) => (
          <a key={item.url} href={item.url} target="__blank">
            <img src={item.icon} alt={item.url} />
          </a>
        ))}
      </IconsWrap>
      <LinksWrap>
        <a target="__blank" href="https://coopah.com/privacy-notice">
          PRIVACY NOTICE
        </a>
        <span className="delimiter">|</span>
        <a target="__blank" href="https://coopah.com/terms-of-use">
          TERMS & CONDITIONS
        </a>
      </LinksWrap>
    </FooterWrap>
  )
}

export default Footer
