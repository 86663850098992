import styled from 'styled-components'

export const ImageWrap = styled.img`
  border-radius: 20px;
  max-height: 483px;
  max-width: 546px;

  @media (max-width: 1150px) {
    max-height: 280px;
  }
`

export const TextBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 432px;

  margin-left: 140px;

  h1 {
    font-family: 'Futura';
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 42px;
    color: #000000;
    margin: 0;
  }

  p {
    margin: 0;
    margin-top: ${(p) => p.theme.spacing.sm};
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 18px;
    line-height: 24px;
    color: #363636;
  }

  a {
    text-decoration-line: underline;
    cursor: pointer;
    color: #ff5700;
  }

  @media (max-width: 1150px) {
    margin-left: 0;
    margin-top: 24px;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 32px 0;
  padding-bottom: 32px;
  border-bottom: 1px solid #cccccc;

  a {
    img {
      height: 60px;
      max-width: 210px;
    }
  }

  @media (max-width: 1150px) {
    justify-content: center;
  }
  @media (max-width: 445px) {
    a {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      img {
      }
    }
  }
`

export const ChatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #f6f6f6;
  border-radius: 8px;
  padding: 24px;

  & > div {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    img {
      margin-right: 8px;
    }
    h6 {
      margin: 0;
    }
  }
  h6 {
    margin: 0 0 10px;
  }
  p {
    margin: 0;
  }
`

export const PurchaseWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

export const PageWrapper = styled.div`
  padding: ${(p) => `${p.theme.spacing.xxl} ${p.theme.spacing.xl}`} 0;

  @media (max-width: 1150px) {
    width: 100%;
    padding: ${(p) => `${p.theme.spacing.sm} ${p.theme.spacing.sm}`} 0;
  }
`
