import React from 'react'
import OrderArrow from '../../../svgs/OrderArrow'
import OrderDirection from '../../../types/OrderDirection'
import OrderIcons from './style'

type OrderButtonProps = {
  field: string
  isChosen: boolean
  chosenDirection: OrderDirection
  setOrder: (orderBy: string, direction: OrderDirection) => void
}

function OrderButton({
  field,
  isChosen,
  chosenDirection,
  setOrder,
}: OrderButtonProps) {
  if (!isChosen) {
    return (
      <OrderIcons onClick={() => setOrder(field, OrderDirection.ASC)}>
        <OrderArrow ascending />

        <OrderArrow />
      </OrderIcons>
    )
  }

  if (chosenDirection === OrderDirection.ASC) {
    return (
      <OrderIcons onClick={() => setOrder(field, OrderDirection.DESC)}>
        <OrderArrow ascending />
      </OrderIcons>
    )
  }

  return (
    <OrderIcons onClick={() => setOrder(field, OrderDirection.ASC)}>
      <OrderArrow />
    </OrderIcons>
  )
}

export default OrderButton
