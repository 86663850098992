import styled from 'styled-components'

export const Input = styled.input`
  outline: none;
  padding: ${(p) => p.theme.spacing.xs} 0;
  border: none;
  width: 100%;
  height: 60px;
  border: 1px solid
    ${(p) => (p.error ? p.theme.colors.error : p.theme.colors.grays[6])};
  font-size: 14px;
  background: #f6f6f6;
  border-radius: 8px;
  padding: 16px 24px;

  &::placeholder {
    color: ${(p) => p.theme.colors.grays[1]};
  }

  &[type='password'] {
    letter-spacing: 8px;

    &::placeholder {
      letter-spacing: normal;
    }
  }
`

export const ErrorMessage = styled.p`
  font-size: 12px;
  color: ${(p) => p.theme.colors.error};
  margin: 4px 0;
`

export const Label = styled.p`
  margin: 0 0 8px;
  color: ${(p) => (p.visible ? '#9A9A9A' : 'transparent')};
  font-weight: 500;
  font-size: 16px;
  top: 10px;
  transition: ${(p) => p.theme.duration.default};
`

export const Wrapper = styled.div`
  margin: ${(p) => p.theme.spacing.xs} 0;
  position: relative;
  width: 366px;
`

export const IconWrapper = styled.button`
  position: absolute;
  right: 5px;
  top: 45px;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
`
