import React from 'react'
import { Paragraph2 } from '../../design-system/typography'
import { IconButton } from '../../design-system/utils'
import CloseIcon from '../../svgs/CloseIcon'
import Container from './style'

type TagProps = {
  label: string
  onRemove: () => void
}

function Tag({ label, onRemove }: TagProps) {
  return (
    <Container>
      <Paragraph2>{label}</Paragraph2>
      <IconButton type="button" onClick={onRemove}>
        <CloseIcon />
      </IconButton>
    </Container>
  )
}
export default Tag
