import React from 'react'

export default function CheckmarkCircled() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99996 17.3334C4.39759 17.3334 0.666626 13.6025 0.666626 9.00008C0.666626 4.39771 4.39759 0.666748 8.99996 0.666748C13.6023 0.666748 17.3333 4.39771 17.3333 9.00008C17.3333 13.6025 13.6023 17.3334 8.99996 17.3334ZM8.99996 16.5001C13.1421 16.5001 16.5 13.1422 16.5 9.00008C16.5 4.85795 13.1421 1.50008 8.99996 1.50008C4.85782 1.50008 1.49996 4.85795 1.49996 9.00008C1.49996 13.1422 4.85782 16.5001 8.99996 16.5001ZM12.2113 6.62212C12.0485 6.4594 11.7847 6.4594 11.622 6.62212L7.74996 10.4942L6.37792 9.12212C6.2152 8.9594 5.95138 8.9594 5.78866 9.12212C5.62595 9.28484 5.62595 9.54866 5.78866 9.71138L7.45533 11.378C7.61805 11.5408 7.88187 11.5408 8.04459 11.378L12.2113 7.21138C12.374 7.04866 12.374 6.78484 12.2113 6.62212Z"
        fill="#F27C38"
      />
    </svg>
  )
}
