import React from 'react'
import { Slider, SwitchInput, SwitchWrapper } from './style'

type SwitchProps = {
  checked: boolean
  onChange: () => void
}

function Switch({ checked, onChange }: SwitchProps) {
  return (
    <SwitchWrapper>
      <SwitchInput type="checkbox" checked={checked} onChange={onChange} />
      <Slider />
    </SwitchWrapper>
  )
}

export default Switch
