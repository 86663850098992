import axios from 'axios'
import OrderDirection from '../types/OrderDirection'
import Roles from '../types/Roles'
import User, { AccountStatus } from '../types/User'
import { TrainingSession } from '../pages/TrainingSessions/TrainingSessionsTable/utils'

const listUsers = (
  limit: number,
  order: OrderDirection,
  orderBy: string,
  role: string,
  search: string,
  offset: number
) => {
  const roleFilter = role !== Roles.ALL ? `&role=${role}` : ''
  const searchFilter = search ? `&search=${search}` : ''

  return axios.get<User[]>(
    `/api/admin/user/list?limit=${limit.toString()}&offset=${offset.toString()}&orderby=${orderBy}&order=${order}${roleFilter}${searchFilter}`,
    {
      withCredentials: true,
    }
  )
}

const downloadUsersCsv = () => {
  window.open('/api/admin/user/csv', '_blank')
}

const editUser = (
  id: string | number,
  firstName: string,
  lastName: string,
  accountStatus: AccountStatus,
  role: Roles,
  subscription: boolean,
  elevated: boolean
) => {
  return axios.put(
    `/api/admin/user/update/${id}`,
    {
      firstName,
      lastName,
      role,
      accountStatus,
      subscription,
      elevated,
    },
    { withCredentials: true }
  )
}

const getClubsByUser = (userId: string | number) => {
  return axios.get(`/api/admin/chat/userchannels/${userId}`, {
    withCredentials: true,
  })
}

const addUserToChannel = (
  userId: string | number,
  channelId: string | number
) => {
  return axios.post(
    '/api/admin/chat/channel/add-user',
    {
      userId,
      channelId,
    },
    { withCredentials: true }
  )
}

const removeUserFromChannel = (
  userId: string | number,
  channelId: string | number
) => {
  return axios.post(
    '/api/admin/chat/channel/remove-user',
    {
      userId,
      channelId,
    },
    { withCredentials: true }
  )
}

const deleteUser = (userId: string | number) => {
  return axios.delete(`/api/admin/user/delete/${userId}`, {
    withCredentials: true,
  })
}

const getUserActivePlan = ({
  userId,
  planId,
  source = '',
}: {
  userId: number | string
  planId: number | string
  source: string
}) => {
  return axios.get<TrainingSession[]>(
    `/api/admin/user/${userId}/plans/${planId}/sessions?source=${source}`,
    {
      withCredentials: true,
    }
  )
}

const usersApi = {
  listUsers,
  downloadUsersCsv,
  editUser,
  getClubsByUser,
  addUserToChannel,
  removeUserFromChannel,
  deleteUser,
  getUserActivePlan,
}

export default usersApi
