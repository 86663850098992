import styled from 'styled-components'
import { TextButton } from '../../../design-system/utils'

export const Wrapper = styled.div`
  margin-top: ${(p) => p.theme.spacing.md};

  > div {
    margin-bottom: ${(p) => p.theme.spacing.sm};
  }
`

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
`

export const EditButton = styled(TextButton)`
  color: ${(p) => p.theme.colors.primary};
  font-size: 14px;
  font-weight: bold;
`
