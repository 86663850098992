import React, { useMemo, useState, useCallback } from 'react'
import { DataGrid } from '@mui/x-data-grid'

import { LabelBold } from '../../../design-system/typography'
import { SocialLinksTableWrap } from './style'
import { IExternalLink } from '../../../types/ClubApiData'

function validateLink(link) {
  /*eslint-disable*/
  const re =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
  /* eslint-enable */
  return re.test(String(link))
}

const columns = [
  {
    field: 'title',
    headerName: 'Title',
    editable: true,
    sortable: false,
    width: 80,
    preProcessEditCellProps: (params) => {
      const isValid = params.props.value?.length < 21
      return { ...params.props, error: !isValid }
    },
  },
  {
    field: 'description',
    headerName: 'Description',
    editable: true,
    sortable: false,
    width: 130,
    preProcessEditCellProps: (params) => {
      const isValid = params.props.value?.length < 81
      return { ...params.props, error: !isValid }
    },
  },
  {
    field: 'link',
    headerName: 'Social link',
    editable: true,
    sortable: false,
    width: 160,
    preProcessEditCellProps: (params) => {
      if (!params.props.value?.length) {
        return params.props
      }
      const isValid = validateLink(params.props.value)
      return { ...params.props, error: !isValid }
    },
  },
]

interface IProps {
  externalLinks: IExternalLink[]
  setExternalLinks: (params: IExternalLink[]) => void
}
function ClubSocialLinks({ externalLinks, setExternalLinks }: IProps) {
  const [error, setError] = useState(null)
  const handleRowEditCommit = useCallback(
    (params) => {
      const { id } = params
      const key = params.field
      const { value } = params

      const targetItem = externalLinks.find((item) => item?.id === id) || {}
      const leftItems = externalLinks.filter((item) => item?.id !== id)
      targetItem[key] = value
      targetItem.id = id
      setExternalLinks([targetItem, ...leftItems])
    },
    [externalLinks, setExternalLinks]
  )

  const rows = useMemo(() => {
    const tempRows = []
    new Array(3 - externalLinks.length).fill(0).forEach(() => {
      tempRows.push({
        id: Math.floor(Math.random() * (999 - 100 + 1) + 100),
        title: '',
        description: '',
        link: '',
      })
    })
    return [...externalLinks, ...tempRows]
  }, [externalLinks])

  return (
    <SocialLinksTableWrap>
      <LabelBold>Add links to club social accounts</LabelBold>
      <DataGrid
        rows={rows}
        columns={columns}
        onCellEditCommit={handleRowEditCommit}
        sx={{
          '& .MuiDataGrid-footerContainer': {
            display: 'none',
          },
        }}
      />
    </SocialLinksTableWrap>
  )
}

export default ClubSocialLinks
