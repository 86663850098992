import React, { useState, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { HeaderContainer, NavContainer, NavItem } from './style'
import Exit from '../../svgs/Exit'
import { IconButton } from '../../design-system/utils'
import authApi from '../../api/auth'
import { AuthContext } from '../../pages/Auth/AuthProvider'
import Logo from '../../assets/images/logo.png'

const navItems = [
  {
    label: 'Users',
    link: '/',
  },
  {
    label: 'Training Plan',
    link: '/training-plan',
  },
  {
    label: 'Club',
    link: '/club',
  },
  {
    label: 'Chat',
    link: '/chat',
  },
  {
    label: 'Rewards',
    link: '/rewards',
  },
  {
    label: 'Coupons',
    link: '/coupons',
  },
]

function Header() {
  const { logout: authLogout, loggedInUser } = useContext(AuthContext)
  const location = useLocation()
  const [loggedOut, setLoggedOut] = useState(false)

  const logout = () => {
    authApi.logout().then(() => {
      authLogout()
      setLoggedOut(true)
    })
  }

  if (location.pathname === '/purchase-success') {
    return null
  }
  return (
    <HeaderContainer>
      <img className="logo" src={Logo} alt="logo" />
      {loggedInUser?.role === 'admin' && loggedInUser && (
        <NavContainer>
          {navItems.map((navItem) => (
            <NavItem
              to={navItem.link}
              key={navItem.link}
              selected={location.pathname === navItem.link}
            >
              {navItem.label}
            </NavItem>
          ))}
          <IconButton onClick={logout}>
            <Exit />
          </IconButton>
        </NavContainer>
      )}
    </HeaderContainer>
  )
}

export default Header
