import React, { useState, useMemo, Fragment } from 'react'
import {
  Column,
  ExpandedState,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  getExpandedRowModel,
  ColumnDef,
  flexRender,
  SortingState,
  getSortedRowModel,
} from '@tanstack/react-table'
import Checkbox from '@mui/material/Checkbox'
import { TrainingSession, filterOptions } from './utils'
import TrainingSessionIntervals from '../TrainingIntervalsTable'
import MultipleSelectChip from '../../../components/MultiSelectDropDown'

interface IProps {
  sessions: TrainingSession[]
}
export default function TrainingSessionsTable({ sessions }: IProps) {
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'date', desc: false },
  ])
  const [columnVisibility, setColumnVisibility] = useState({
    id: true,
    title: true,
    date: true,
    day: false,
    week: false,
    description: false,
    embedUrl: false,
    completed: true,
    sportType: false,
    activityType: true,
    phase: false,
    templateOrder: false,
    status: true,
    createdAt: false,
    updatedAt: false,
  })

  const columns = React.useMemo<ColumnDef<TrainingSession>[]>(
    () => [
      {
        accessorKey: 'id',
        enableColumnFilter: true,
        header: () => 'ID',
        filterFn: 'includesString',
        cell: ({ row, getValue }) => {
          return (
            <>
              {row?.getCanExpand() && (
                <button
                  onClick={() => row.toggleExpanded()}
                  style={{ cursor: 'pointer' }}
                  type="button"
                >
                  {row.getIsExpanded() ? '👇' : '👉'}
                </button>
              )}
              &nbsp;{getValue()}
            </>
          )
        },
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'title',
        header: () => 'Title',
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'date',
        header: () => 'Date',
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'day',
        header: () => 'Day',
        footer: (props) => props.column.id,
        filterFn: 'includesString',
      },
      {
        accessorKey: 'week',
        header: () => 'Week',
        footer: (props) => props.column.id,
        filterFn: 'includesString',
      },
      {
        accessorKey: 'description',
        header: () => 'Description',
        footer: (props) => props.column.id,
        filterFn: 'includesString',
      },
      {
        accessorKey: 'embedUrl',
        header: () => 'Embed Url',
        footer: (props) => props.column.id,
        filterFn: 'includesString',
      },
      {
        accessorKey: 'completed',
        header: () => 'Completed',
        footer: (props) => props.column.id,
        cell: ({ row }) => {
          return row?.original?.completed ? '✅' : '❌'
        },
        filterFn: 'equals',
      },
      {
        accessorKey: 'sportType',
        header: () => 'Sport Type',
        footer: (props) => props.column.id,
        filterFn: 'arrIncludesSome',
      },
      {
        accessorKey: 'activityType',
        header: () => 'Activity Type',
        footer: (props) => props.column.id,
        filterFn: 'arrIncludesSome',
      },
      {
        accessorKey: 'phase',
        header: () => 'Phase',
        footer: (props) => props.column.id,
        filterFn: 'arrIncludesSome',
      },
      {
        accessorKey: 'templateOrder',
        header: () => 'Template Order',
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'status',
        header: () => 'Status',
        footer: (props) => props.column.id,
        filterFn: 'arrIncludesSome',
      },
      {
        accessorKey: 'createdAt',
        header: () => 'Created At',
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'updatedAt',
        header: () => 'Updated At',
        footer: (props) => props.column.id,
      },
    ],
    []
  )

  const [expanded, setExpanded] = React.useState<ExpandedState>({})

  const table = useReactTable({
    data: sessions,
    columns,
    state: {
      expanded,
      pagination: {
        pageSize: 500,
        pageIndex: 0,
      },
      sorting,
      columnVisibility,
    },
    initialState: {
      columnFilters: [
        {
          id: 'status',
          value: ['active', 'taper'],
        },
      ],
      sorting: [
        {
          id: 'date',
          desc: false,
        },
      ],
    },
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    getRowCanExpand: (row) => {
      return !!row?.original?.intervals?.length
    },

    debugTable: process.env.NODE_ENV === 'development',
  })

  return (
    <div className="p-2">
      <div className="h-2" />

      <div className="inline-block border border-black shadow rounded mx-auto">
        <div className="px-1 border-b border-black">
          <label htmlFor="all">
            <input
              id="all"
              type="checkbox"
              checked={table.getIsAllColumnsVisible()}
              onChange={table.getToggleAllColumnsVisibilityHandler()}
            />{' '}
            Toggle All
          </label>
        </div>
        {table.getAllLeafColumns().map((column) => {
          return (
            <div key={column.id} className="px-1">
              <label htmlFor={column?.id}>
                <input
                  id={column.id}
                  type="checkbox"
                  checked={column.getIsVisible()}
                  onChange={column.getToggleVisibilityHandler()}
                />{' '}
                {column.id}
              </label>
            </div>
          )
        })}
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full">
              <thead className="bg-white">
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 text-center"
                        >
                          {header.isPlaceholder ? null : (
                            <div>
                              {/* eslint-disable-next-line */}
                              <div
                                className={
                                  header.column.getCanSort()
                                    ? 'flex justify-center cursor-pointer select-none'
                                    : 'flex justify-center'
                                }
                                onClick={() =>
                                  header.column.getToggleSortingHandler()
                                }
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {{
                                  asc: ' 🔼',
                                  desc: ' 🔽',
                                }[header.column.getIsSorted() as string] ??
                                  null}
                              </div>

                              {header.column.getCanFilter() ? (
                                <div>
                                  <Filter column={header.column} />
                                </div>
                              ) : null}
                            </div>
                          )}
                        </th>
                      )
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <Fragment key={row?.id}>
                      <tr key={row.id} className="border-t border-gray-200">
                        {row.getVisibleCells().map((cell) => {
                          return (
                            <td
                              key={cell.id}
                              className="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3 text-center"
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          )
                        })}
                      </tr>
                      {row.getIsExpanded() && (
                        <tr>
                          <td colSpan={columns.length}>
                            <TrainingSessionIntervals
                              intervals={row?.original?.intervals}
                            />
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="mt-4">{table.getRowModel().rows.length} Rows</div>
    </div>
  )
}

function Filter({ column }: { column: Column<any, unknown> }) {
  const columnFilterValue = column.getFilterValue()

  const sortedUniqueValues = useMemo(() => {
    return filterOptions[column?.id as unknown as string] || []
  }, [column?.id])

  if (column?.id === 'completed') {
    return (
      <Checkbox
        checked={!!columnFilterValue}
        onChange={(e) => column.setFilterValue(e.target.checked)}
      />
    )
  }
  return sortedUniqueValues?.length ? (
    <div>
      <MultipleSelectChip
        data={sortedUniqueValues?.map((v) => ({ key: v, title: v })) ?? []}
        selectedData={
          (column.getFilterValue() as string[])?.map((v) => ({
            key: v,
            title: v,
          })) ?? []
        }
        label={column?.id}
        onChange={({ key }) => {
          const existingFilter = (column.getFilterValue() as string[]) || []
          const newFilterState = existingFilter?.includes(key as string)
            ? existingFilter?.filter((v) => v !== key)
            : [...existingFilter, key]
          column.setFilterValue(newFilterState)
        }}
      />
      <div className="h-1" />
    </div>
  ) : (
    <>
      <input
        type="text"
        value={(columnFilterValue ?? '') as string}
        onChange={(e) => {
          column.setFilterValue(e.target.value)
        }}
        placeholder="Search..."
        className="w-36 border shadow rounded"
      />
      <div className="h-1" />
    </>
  )
}
