import React, { useRef, useState } from 'react'
import { ErrorMessage } from '../../design-system/typography'
import CrossedEye from '../../svgs/CrossedEye'
import Eye from '../../svgs/Eye'
import { IconWrapper, Input, Label, Wrapper } from './style'

type InputFieldProps = {
  placeholder?: string
  type?: string
  error?: boolean
  errorMessage?: string
  defaultValue?: string | Date
  onChangeEvent?: (inputValue: string) => void
}

function InputField({
  placeholder,
  type,
  error,
  errorMessage,
  defaultValue,
  onChangeEvent,
}: InputFieldProps) {
  const inputField = useRef(null)
  const [isFilled, setFilled] = useState(false)
  const [passwordHidden, setPasswordHidden] = useState(true)
  const [value, setValue] = useState(defaultValue)

  const handleChange = (event) => {
    event.preventDefault()
    setValue(inputField.current.value)
    if (inputField.current.value) {
      setFilled(true)
    } else {
      setFilled(false)
    }
    if (onChangeEvent) {
      onChangeEvent(inputField.current.value)
    }
  }

  const getEyeIcon = () => {
    if (type !== 'password' || !isFilled) {
      return false
    }

    if (passwordHidden) {
      return (
        <IconWrapper onClick={() => setPasswordHidden(false)} type="button">
          <Eye />
        </IconWrapper>
      )
    }
    return (
      <IconWrapper onClick={() => setPasswordHidden(true)} type="button">
        <CrossedEye />
      </IconWrapper>
    )
  }

  const getType = () => {
    if (type !== 'password' || passwordHidden) {
      return type
    }

    return 'text'
  }

  return (
    <Wrapper>
      <Label visible>{placeholder}</Label>
      <Input
        type={getType()}
        error={error}
        placeholder={placeholder}
        ref={inputField}
        onChange={handleChange}
        value={value}
      />
      {error && <ErrorMessage>{errorMessage}</ErrorMessage>}
      {getEyeIcon()}
    </Wrapper>
  )
}
export default InputField
