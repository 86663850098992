import React, { useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import qs from 'qs'
import Signup from '../../components/Signup'
import { AuthContext } from '../Auth/AuthProvider'
import { stripeUrl } from '../../constants/stripe'

const Purchase = () => {
  const { push } = useHistory()
  const { isAuthorized, loggedInUser } = useContext(AuthContext)
  const { search } = useLocation()
  const { offer } = qs.parse(search, { ignoreQueryPrefix: true })
  if (
    isAuthorized &&
    loggedInUser &&
    loggedInUser?.accountStatus === 'default'
  ) {
    const { REACT_APP_STRIPE_ID, REACT_APP_PAYMENT_LINK_2 } = process.env
    const paymentLinkId = offer ? REACT_APP_PAYMENT_LINK_2 : REACT_APP_STRIPE_ID
    window.location.href = `${stripeUrl}${paymentLinkId}?prefilled_email=${loggedInUser?.email}&client_reference_id=${loggedInUser?.id}`
    return null
  }
  if (
    isAuthorized &&
    loggedInUser &&
    loggedInUser?.accountStatus !== 'default' &&
    loggedInUser?.role !== 'admin'
  ) {
    push('/purchase-failed')
  }
  if (loggedInUser?.role === 'admin') {
    push('/')
  }
  return (
    <div>
      <Signup />
    </div>
  )
}

export default Purchase
