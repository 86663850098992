const gridTitles = [
  {
    title: 'Plan',
    field: 'name',
  },
  {
    title: 'Type of plan',
    field: 'type',
  },
  {
    title: 'Uploaded',
    field: 'createdAt',
  },
  {
    title: 'Distance',
    field: 'formattedDistance',
  },
  {
    title: 'Weeks',
    field: 'plannedWeeks',
  },
  {
    title: 'Levels',
    field: 'level',
  },
]

export default gridTitles
