import { Redirect } from 'react-router-dom'
import React, { useEffect, useState, useContext } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import usersApi from '../../api/users'
import DropdownSelector from '../../components/DropdownSelector'
import OrderByTitle from '../../components/OrderByTitle'
import { Heading1Bold, Paragraph2 } from '../../design-system/typography'
import { Button, PageWrapper } from '../../design-system/utils'
import OrderDirection from '../../types/OrderDirection'
/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
import User from '../../types/User'
import {
  UserGrid,
  Divider,
  GridTitle,
  EmptyGridMessage,
  FilterWrapper,
  HeadingWrapper,
  GridWrapper,
} from './style'
import Loader from '../../components/Loader'
import LabeledField from '../../types/LabeledField'
import UserRowData from './UserRowData'
import SearchField from '../../components/SearchField'
import { gridTitles, roles } from './constants'
import withAuthorization from '../../components/HOC/withAuthorization'
import { AuthContext } from '../Auth/AuthProvider'

function UserList() {
  const { loggedInUser } = useContext(AuthContext)
  const [users, setUsers] = useState<User[]>([])
  const [orderBy, setOrderBy] = useState('createdAt')
  const [orderDirection, setOrderDirection] = useState(OrderDirection.DESC)
  const [roleFilter, setRoleFilter] = useState(roles[0])
  const [unauthorized, setUnauthorized] = useState(false)
  const [loading, setLoading] = useState(true)
  const [limit, setLimit] = useState(10)
  const [searchWord, setSearchWord] = useState('')
  const [hasMore, setHasMore] = useState(true)
  const [detailedUser, setDetailedUser] = useState(null)
  const [updateTrigger, setUpdateTrigger] = useState(false)

  useEffect(() => {
    if (loggedInUser?.role === 'admin') {
      usersApi
        .listUsers(
          limit,
          orderDirection,
          orderBy,
          roleFilter.field,
          searchWord,
          0
        )
        .then((res) => {
          if (res.data.length < limit) {
            setHasMore(false)
          } else {
            setHasMore(true)
          }
          setUsers(res.data)
          setLoading(false)
        })
        .catch((err) => {
          if (err.response.status === 401 || err.response.status === 403) {
            setUnauthorized(true)
          }
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    orderBy,
    orderDirection,
    roleFilter,
    searchWord,
    updateTrigger,
    loggedInUser,
  ])

  const setOrder = (orderByField: string, direction: OrderDirection) => {
    setOrderBy(orderByField)
    setOrderDirection(direction)
  }

  const filterByRole = (role: LabeledField) => {
    setRoleFilter(role)
  }

  const loadMoreUsers = () => {
    usersApi
      .listUsers(
        10,
        orderDirection,
        orderBy,
        roleFilter.field,
        searchWord,
        limit
      )
      .then((res) => {
        if (res.data.length < 10) {
          setHasMore(false)
        }
        setUsers((loadedUsers) => [...loadedUsers, ...res.data])
        setLimit((previousLimit) => previousLimit + 10)
      })
  }

  const triggerUpdate = () => {
    setUpdateTrigger((updateTriggered) => !updateTriggered)
  }

  if (loggedInUser?.role !== 'admin') {
    return null
  }

  if (unauthorized) {
    return <Redirect to="/login" />
  }

  if (loading) {
    return <Loader />
  }

  const getGrid = () => {
    if (users.length === 0) {
      return (
        <EmptyGridMessage>No users match the selected filter.</EmptyGridMessage>
      )
    }

    const getGridTitle = (gridTitle: LabeledField) => {
      if (
        gridTitle.field === 'trainingplan' ||
        gridTitle.field === 'targetrace' ||
        gridTitle.field === 'club'
      ) {
        return <GridTitle key={gridTitle.field}>{gridTitle.label}</GridTitle>
      }
      return (
        <OrderByTitle
          title={gridTitle.label}
          field={gridTitle.field}
          isChosen={gridTitle.field === orderBy}
          chosenDirection={orderDirection}
          setOrder={setOrder}
          key={gridTitle.field}
        />
      )
    }

    return (
      <InfiniteScroll
        dataLength={users.length}
        next={loadMoreUsers}
        hasMore={hasMore}
        loader={<Loader />}
        style={{ overflow: 'visible' }}
        scrollThreshold={0.8}
        scrollableTarget="userGrid"
      >
        <UserGrid>
          {gridTitles.map((gridTitle) => getGridTitle(gridTitle))}

          {users.map((user: User) => (
            <React.Fragment key={user.email}>
              <UserRowData
                user={user}
                openDetails={setDetailedUser}
                triggerUpdate={triggerUpdate}
                detailedUser={detailedUser}
              />
              <Divider />
            </React.Fragment>
          ))}
        </UserGrid>
      </InfiniteScroll>
    )
  }

  return (
    <PageWrapper>
      <HeadingWrapper>
        <Heading1Bold>Users</Heading1Bold>
        <Button secondary onClick={usersApi.downloadUsersCsv}>
          Download
        </Button>
        <FilterWrapper>
          <Paragraph2>Filter by role:</Paragraph2>
          <DropdownSelector
            currentSelection={roleFilter}
            options={roles}
            onSelect={filterByRole}
            boldTitle
          />
          <SearchField placeholder="Search user" onSearch={setSearchWord} />
        </FilterWrapper>
      </HeadingWrapper>
      <GridWrapper id="userGrid">{getGrid()}</GridWrapper>
    </PageWrapper>
  )
}

export default withAuthorization(UserList)
