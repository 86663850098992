import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  > img {
    margin-right: ${(p) => p.theme.spacing.sm};
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: ${(p) => p.theme.spacing.sm};
  border-bottom: 1px solid #eaeaea;
`

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  > svg {
    margin-right: 5px;
  }
`

export const InfoLabel = styled.div`
  display: flex;
  margin-top: 10px;

  > p {
    margin-right: 5px;
  }
`
