import React, { useState } from 'react'
import { Paragraph2, Paragraph2Bold } from '../../design-system/typography'
import OrderArrow from '../../svgs/OrderArrow'
import LabeledField from '../../types/LabeledField'
import {
  CurrentSelection,
  Divider,
  OptionLabel,
  Options,
  SelectorWrapper,
} from './style'

type DropdownSelectorProps = {
  currentSelection: LabeledField
  options: Array<LabeledField>
  onSelect: (selection: LabeledField) => void
  boldTitle?: boolean
  placeholder?: string
}

function DropdownSelector({
  currentSelection,
  options,
  onSelect,
  boldTitle,
  placeholder,
}: DropdownSelectorProps) {
  const [open, setOpen] = useState(false)

  const select = (selection) => {
    onSelect(selection)
    setOpen(false)
  }

  return (
    <SelectorWrapper>
      <CurrentSelection
        onClick={() => setOpen((state) => !state)}
        type="button"
      >
        {boldTitle && currentSelection ? (
          <Paragraph2Bold>{currentSelection?.label}</Paragraph2Bold>
        ) : (
          <Paragraph2>{currentSelection?.label}</Paragraph2>
        )}
        {!currentSelection && placeholder && (
          <Paragraph2>{placeholder}</Paragraph2>
        )}
        <OrderArrow />
      </CurrentSelection>
      <Options open={open}>
        {options.map((option) => (
          <React.Fragment key={option.field}>
            <OptionLabel
              disabled={option.disabled}
              open={open}
              onClick={() => select(option)}
              isSelected={option?.field === currentSelection?.field}
            >
              {option.label}
            </OptionLabel>
            <Divider />
          </React.Fragment>
        ))}
      </Options>
    </SelectorWrapper>
  )
}

export default DropdownSelector
