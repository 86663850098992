import React, { useEffect, useState } from 'react'
import channelApi from '../../../api/channels'
import usersApi from '../../../api/users'
import ChannelInfoHeader from '../../../components/ChannelInfoHeader'
import Checkbox from '../../../components/Checkbox'
import SearchField from '../../../components/SearchField'
import Tag from '../../../components/Tag'
import {
  ChannelsContainer,
  ChannelWrapper,
  Fade,
  TagWrapper,
  Title,
  Wrapper,
} from './style'

const LIMIT = 30

type EditUserChannelsProps = {
  joinedChannels
  userId: string | number
  triggerUpdate?: () => void
}

function EditUserChannels({
  joinedChannels,
  triggerUpdate,
  userId,
}: EditUserChannelsProps) {
  const [search, setSearch] = useState('')
  const [allChannels, setAllChannels] = useState([])
  const [joinedChannelIds, setJoinedChannelIds] = useState([])
  const [offset, setOffset] = useState(0)

  useEffect(() => {
    setJoinedChannelIds(joinedChannels.map((joinedChannel) => joinedChannel.id))
  }, [joinedChannels])

  useEffect(() => {
    channelApi.getAllChannels({ limit: LIMIT, offset }).then((res) => {
      setAllChannels((loadedChannels) => [...loadedChannels, ...res.data])
      if (res.data.length >= LIMIT) {
        setOffset((prevOffset) => prevOffset + LIMIT)
      }
    })
  }, [offset])

  const removeFromEditedChannels = (channelId) => {
    usersApi
      .removeUserFromChannel(userId, channelId)
      .then(() => triggerUpdate())
  }

  const toggleJoin = (toggledChannel) => {
    if (joinedChannelIds.includes(toggledChannel.id)) {
      removeFromEditedChannels(toggledChannel.id)
    } else {
      usersApi
        .addUserToChannel(userId, toggledChannel.id)
        .then(() => triggerUpdate())
    }
  }

  return (
    <Wrapper>
      <Title>Add user to channels</Title>
      <SearchField placeholder="Search channel" onSearch={setSearch} />
      <TagWrapper>
        {joinedChannels.map((channel) => (
          <Tag
            label={channel.name}
            key={channel.id}
            onRemove={() => removeFromEditedChannels(channel.id)}
          />
        ))}
      </TagWrapper>
      <ChannelsContainer>
        {allChannels.map(
          (channel) =>
            channel.name.toLowerCase().includes(search.toLowerCase()) && (
              <ChannelWrapper key={channel.id}>
                <ChannelInfoHeader
                  name={channel.name}
                  memberCount={channel.member_count}
                  imageUrl={channel.image}
                />
                <Checkbox
                  checked={joinedChannelIds.includes(channel.id)}
                  toggleCheck={() => toggleJoin(channel)}
                />
              </ChannelWrapper>
            )
        )}
      </ChannelsContainer>
      <Fade />
    </Wrapper>
  )
}
export default EditUserChannels
