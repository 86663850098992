import React from 'react'
import usersApi from '../../../api/users'
import DropdownSelector from '../../../components/DropdownSelector'
import { Paragraph2, Paragraph2Bold } from '../../../design-system/typography'
import { RoundImage } from '../../../design-system/utils'
import LabeledField from '../../../types/LabeledField'
import User, { AccountStatus } from '../../../types/User'
import dateConverter from '../../../utils/DateConverter'
import { accountStatusOptions } from '../constants'
import UserDetails from '../UserDetails'
import { Email, NameWrapper } from './style'

type UserRowDataProps = {
  user: User
  detailedUser: User | null
  openDetails: (user: User) => void
  triggerUpdate: () => void
}

function UserRowData({
  user,
  openDetails,
  triggerUpdate,
  detailedUser,
}: UserRowDataProps) {
  const { id, firstName, lastName, role } = user
  const setDonation = (accountStatus: LabeledField) => {
    if (accountStatus?.field === AccountStatus.subscribed) {
      return
    }
    usersApi
      .editUser(
        id,
        firstName,
        lastName,
        accountStatus?.field as AccountStatus,
        role,
        accountStatus?.field === AccountStatus.subscribed,
        accountStatus?.field === AccountStatus.donated
      )
      .then(() => triggerUpdate())
  }

  return (
    <>
      <NameWrapper onClick={() => openDetails(user)}>
        <RoundImage src={`/api/admin/user/avatar/${user.id}`} />
        <Paragraph2Bold>
          {user.firstName} {user.lastName}
        </Paragraph2Bold>
      </NameWrapper>
      <Email onClick={() => openDetails(user)}>{user.email}</Email>
      <Paragraph2>{dateConverter(user.createdAt)}</Paragraph2>
      <Paragraph2>{dateConverter(user.updatedAt)}</Paragraph2>
      <Paragraph2>
        {user?.trainingPlans[0]?.targetDistance &&
        user?.trainingPlans[0]?.plannedWeeks
          ? `${user?.trainingPlans[0]?.targetDistance} / ${user?.trainingPlans[0]?.plannedWeeks} weeks`
          : '-'}
      </Paragraph2>
      <Paragraph2>
        {user?.trainingPlans[0]?.targetDate
          ? dateConverter(user?.trainingPlans[0]?.targetDate)
          : '-'}
      </Paragraph2>
      <Paragraph2>{user?.club?.name}</Paragraph2>
      <DropdownSelector
        currentSelection={accountStatusOptions?.find(
          ({ field }) => field === user.accountStatus
        )}
        options={accountStatusOptions}
        onSelect={setDonation}
      />
      {detailedUser?.id === user.id && (
        <UserDetails
          user={user}
          closeCard={() => openDetails(null)}
          triggerUpdate={triggerUpdate}
        />
      )}
    </>
  )
}

export default UserRowData
