import styled from 'styled-components'
import { Button } from '../../design-system/utils'

export const CreateButton = styled(Button)`
  width: 170px;
  padding: 10px 0;
`

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(p) => p.theme.spacing.md};

  > button {
    margin-left: ${(p) => p.theme.spacing.m};
  }
`
