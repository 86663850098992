import React, { useEffect, useState } from 'react'
import channelApi from '../../../api/channels'
import ChannelInfoHeader from '../../../components/ChannelInfoHeader'
import EditDropdown from '../../../components/EditDropdown'
import { Paragraph2 } from '../../../design-system/typography'
import CheckmarkCircled from '../../../svgs/CheckmarkCircled'
import { dateToMonthYear } from '../../../utils/DateConverter'
import ChatEditor from '../ChatEditor'
import {
  ActionsWrapper,
  AutoAssigned,
  Description,
  Divider,
  Wrapper,
} from './style'

type ChatCardProps = {
  chat
  onUpdate: () => void
}

export default function ChatCard({ chat, onUpdate }: ChatCardProps) {
  const [distance, setDistance] = useState(null)
  const [months, setMonths] = useState(null)
  const [editModalOpen, setEditModalOpen] = useState(false)

  useEffect(() => {
    if (!chat.assignDistances || chat.assignDistances.length === 4) {
      setDistance('All')
    } else {
      setDistance(chat.assignDistances.join(', '))
    }

    if (!chat.assignDateFrom || !chat.assignDateTo) {
      setMonths('All')
    } else {
      const from = dateToMonthYear(chat.assignDateFrom)
      const to = dateToMonthYear(chat.assignDateTo)
      setMonths(`${from} - ${to}`)
    }
  }, [chat])

  const deleteChannel = () => {
    channelApi.deleteChannel(chat.id).then(() => onUpdate())
  }

  return (
    <>
      <Wrapper>
        <ActionsWrapper>
          <EditDropdown
            editLabel="Edit channel"
            deleteLabel="Delete channel"
            deleteAction={deleteChannel}
            editAction={() => setEditModalOpen(true)}
          />
        </ActionsWrapper>
        <ChannelInfoHeader
          name={chat.name}
          memberCount={chat.member_count}
          imageUrl={chat.image}
          distance={distance}
          months={months}
          key={chat.name}
        />
        {chat.description ? (
          <Description>{chat.description}</Description>
        ) : (
          <Description noDescription>No description available</Description>
        )}
        {chat.autoAssign && (
          <AutoAssigned>
            <CheckmarkCircled />
            <Paragraph2>
              New users are <strong>auto-assigned</strong> to this channel
            </Paragraph2>
          </AutoAssigned>
        )}
        <Divider />
      </Wrapper>
      {editModalOpen && (
        <ChatEditor
          defaultChat={chat}
          onUpdate={onUpdate}
          closeModal={() => setEditModalOpen(false)}
        />
      )}
    </>
  )
}
