import React from 'react'
import {
  Heading5Bold,
  LabelSmall,
  LabelSmallBold,
} from '../../design-system/typography'
import { RoundImage } from '../../design-system/utils'
import UsersIcon from '../../svgs/UsersIcon'
import { InfoLabel, LabelContainer, TextContainer, Wrapper } from './style'

type ChannelInfoHeaderProps = {
  name: string
  memberCount: number
  imageUrl: string
  distance?: string
  months?: string
}

function ChannelInfoHeader({
  name,
  memberCount,
  imageUrl,
  distance,
  months,
}: ChannelInfoHeaderProps) {
  return (
    <Wrapper>
      <RoundImage
        src={imageUrl || '/assets/images/avatar-placeholder.svg'}
        alt={name}
      />
      <TextContainer>
        <Heading5Bold>{name}</Heading5Bold>
        <LabelContainer>
          <UsersIcon />
          <InfoLabel>
            <LabelSmallBold>{memberCount || '0'} Members </LabelSmallBold>
          </InfoLabel>
          {distance && (
            <InfoLabel>
              <LabelSmall>Distance </LabelSmall>
              <LabelSmallBold> {distance}</LabelSmallBold>
            </InfoLabel>
          )}
          {months && (
            <InfoLabel>
              <LabelSmall>Month(s)</LabelSmall>
              <LabelSmallBold>{months}</LabelSmallBold>
            </InfoLabel>
          )}
        </LabelContainer>
      </TextContainer>
    </Wrapper>
  )
}
export default ChannelInfoHeader
