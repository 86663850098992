import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: ${(p) => p.theme.headerHeight};
  position: sticky;
  background: ${(p) => p.theme.colors.white};
  z-index: 10;
  padding: ${(p) => p.theme.spacing.sm} ${(p) => p.theme.spacing.xl};

  .logo {
    max-width: 160px;
  }
`

export const NavContainer = styled.div`
  display: flex;
`

export const NavItem = styled(Link)<{ selected: boolean }>`
  font-size: 14px;
  margin-right: ${(p) => p.theme.spacing.lg};
  color: inherit;
  text-decoration: none;
  ${(p) =>
    p.selected &&
    `
    color: ${p.theme.colors.primary};
	font-weight: 700;
  `}
`
