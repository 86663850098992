import React from 'react'
import styled from 'styled-components'

type OrderArrowProps = {
  ascending?: boolean
}

function OrderArrow({ ascending }: OrderArrowProps) {
  return (
    <SVG
      width="6"
      height="3"
      viewBox="0 0 6 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ascending={ascending}
    >
      <path
        d="M5.11657 0.247002C5.25635 0.127195 5.46678 0.143382 5.58659 0.283157C5.7064 0.422932 5.69021 0.633366 5.55043 0.753173L3.2171 2.75317C3.09227 2.86017 2.90807 2.86017 2.78324 2.75317L0.449905 0.753173C0.31013 0.633366 0.293943 0.422932 0.41375 0.283157C0.533557 0.143382 0.743991 0.127195 0.883766 0.247002L3.00017 2.06106L5.11657 0.247002Z"
        fill="black"
      />
    </SVG>
  )
}

const SVG = styled.svg`
  ${(p) => p.ascending && `transform: rotate(180deg);`}
`

export default OrderArrow
