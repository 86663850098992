import styled from 'styled-components'

export const Label = styled.p`
  margin: 0 0 8px;
  color: ${(p) => (p.visible ? '#9A9A9A' : 'transparent')};
  font-weight: 500;
  font-size: 16px;
  top: 10px;
  transition: ${(p) => p.theme.duration.default};
`

export const Wrapper = styled.div`
  margin: ${(p) => p.theme.spacing.xs} 0;
  position: relative;
  width: 366px;
`

export const ChildWrap = styled.div`
  outline: none;
  padding: ${(p) => p.theme.spacing.xs} 0;
  border: none;
  width: 100%;
  height: 60px;
  border: 1px solid
    ${(p) => (p.error ? p.theme.colors.error : p.theme.colors.grays[6])};
  font-size: 14px;
  background: #f6f6f6;
  border-radius: 8px;
  padding: 16px 24px;

  select &::placeholder {
    color: ${(p) => p.theme.colors.grays[1]};
  }
`
