import React from 'react'
import {
  Heading5Bold,
  LabelSmall,
  LabelSmallBold,
} from '../../../design-system/typography'
import { RoundImage } from '../../../design-system/utils'
import UsersIcon from '../../../svgs/UsersIcon'
import { InfoLabel, LabelContainer, TextContainer, Wrapper } from './style'

type ClubInfoHeaderProps = {
  name: string
  memberCount: number
  imageUrl: string
  clubCode: string
  donated: boolean
}

function ClubInfoHeader({
  name,
  memberCount,
  imageUrl,
  clubCode,
  donated,
}: ClubInfoHeaderProps) {
  return (
    <Wrapper>
      <RoundImage
        src={imageUrl || '/assets/images/avatar-placeholder.svg'}
        alt={name}
      />
      <TextContainer>
        <Heading5Bold>{name}</Heading5Bold>
        <LabelContainer>
          <UsersIcon />
          <InfoLabel>
            <LabelSmallBold>{memberCount || '0'} Members </LabelSmallBold>
          </InfoLabel>
          {clubCode && (
            <InfoLabel>
              <LabelSmall>Club Code </LabelSmall>
              <LabelSmallBold> {clubCode}</LabelSmallBold>
            </InfoLabel>
          )}

          <InfoLabel>
            <LabelSmall>Donated </LabelSmall>
            <LabelSmallBold> {donated ? 'Yes' : 'No'}</LabelSmallBold>
          </InfoLabel>
        </LabelContainer>
      </TextContainer>
    </Wrapper>
  )
}
export default ClubInfoHeader
