import React from 'react'
import { Wrapper, Label, ChildWrap } from './style'

function DropdownSelectorWrapper({ children, label }) {
  return (
    <Wrapper>
      <Label visible={!!label}>{label}</Label>

      <ChildWrap>{children}</ChildWrap>
    </Wrapper>
  )
}

export default DropdownSelectorWrapper
