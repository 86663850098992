import React from 'react'

export default function Exit() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 0.5C11 0.776142 10.7761 1 10.5 1H2.5C1.67157 1 1 1.67157 1 2.5V15.5C1 16.3284 1.67157 17 2.5 17H10.5C10.7761 17 11 17.2239 11 17.5C11 17.7761 10.7761 18 10.5 18H2.5C1.11929 18 0 16.8807 0 15.5V2.5C0 1.11929 1.11929 0 2.5 0H10.5C10.7761 0 11 0.223858 11 0.5ZM13.1464 12.1464L16.2929 9H5.5C5.22386 9 5 8.77614 5 8.5C5 8.22386 5.22386 8 5.5 8H16.2929L13.1464 4.85355C12.9512 4.65829 12.9512 4.34171 13.1464 4.14645C13.3417 3.95118 13.6583 3.95118 13.8536 4.14645L17.8536 8.14645C18.0488 8.34171 18.0488 8.65829 17.8536 8.85355L13.8536 12.8536C13.6583 13.0488 13.3417 13.0488 13.1464 12.8536C12.9512 12.6583 12.9512 12.3417 13.1464 12.1464Z"
        fill="black"
      />
    </svg>
  )
}
