import React, { useState } from 'react'
import rewardsApi from '../../../api/rewards'
import ImageUploader from '../../../components/ImageUploader'
import InputField from '../../../components/InputField'
import Modal from '../../../components/Modal'
import { Heading2Bold } from '../../../design-system/typography'
import { Button, TextButton } from '../../../design-system/utils'
import validateWebsite from '../../../utils/ValidateWebsite'
import { Form, InputFields, Error } from './style'

type CreateRewardProps = {
  onClose: () => void
  onUpdate: () => void
}

function CreateReward({ onClose, onUpdate }: CreateRewardProps) {
  const [thumbnail, setThumbnail] = useState(null)
  const [title, setTitle] = useState('')
  const [shopName, setShopName] = useState('')
  const [website, setWebsite] = useState('')
  const [code, setCode] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)
  const [invalidWebsite, setInvalidWebsite] = useState(false)

  const submitReward = (e) => {
    e.preventDefault()

    if (!validateWebsite(website)) {
      setInvalidWebsite(true)
      return
    }

    setInvalidWebsite(false)
    rewardsApi
      .createReward(thumbnail, title, shopName, website, code)
      .then(() => {
        onUpdate()
        onClose()
      })
      .catch((err) => {
        if (err.response.status === 422) {
          setErrorMessage(
            'Unable to save changes. Please make sure that all fields are filled.'
          )
        } else {
          setErrorMessage('Something went wrong. Please try again.')
        }
      })
  }

  return (
    <Modal onClose={onClose} width="695px">
      <Heading2Bold>Create a reward</Heading2Bold>
      <Form onSubmit={submitReward}>
        <InputFields>
          <ImageUploader setImage={setThumbnail} />
          <div>
            <InputField placeholder="Title" onChangeEvent={setTitle} />
            <InputField
              placeholder="Shop/brand name"
              onChangeEvent={setShopName}
            />
            <InputField
              placeholder="Website"
              onChangeEvent={setWebsite}
              error={invalidWebsite}
              errorMessage="Enter a valid website"
            />
            <InputField placeholder="Discount code" onChangeEvent={setCode} />
          </div>
        </InputFields>
        <Error>{errorMessage}</Error>
        <Button secondary type="submit">
          Create reward
        </Button>
        <TextButton type="button" onClick={onClose}>
          Cancel
        </TextButton>
      </Form>
    </Modal>
  )
}
export default CreateReward
