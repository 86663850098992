import React from 'react'

export default function SearchIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.378 14.7887L12.1662 11.5769C13.2037 10.4016 13.8333 8.85767 13.8333 7.16667C13.8333 3.48477 10.8486 0.5 7.16667 0.5C3.48477 0.5 0.5 3.48477 0.5 7.16667C0.5 10.8486 3.48477 13.8333 7.16667 13.8333C8.85767 13.8333 10.4016 13.2037 11.5769 12.1662L14.7887 15.378C14.9514 15.5407 15.2152 15.5407 15.378 15.378C15.5407 15.2152 15.5407 14.9514 15.378 14.7887ZM13 7.16667C13 10.3883 10.3883 13 7.16667 13C3.94501 13 1.33333 10.3883 1.33333 7.16667C1.33333 3.94501 3.94501 1.33333 7.16667 1.33333C10.3883 1.33333 13 3.94501 13 7.16667Z"
        fill="black"
      />
    </svg>
  )
}
