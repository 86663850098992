import React, { useState } from 'react'
import authApi from '../../../api/auth'
import AuthFormWrapper from '../../../components/AuthFormWrapper'
import InputField from '../../../components/InputField'
import {
  LabelBig,
  Paragraph1Bold,
  Paragraph2,
} from '../../../design-system/typography'
import { Button, TextButton } from '../../../design-system/utils'

function ResetMailSender() {
  const [emailValid, setEmailValid] = useState(true)
  const [email, setEmail] = useState('')
  const [emailSent, setEmailSent] = useState(false)
  const [emailResent, setEmailResent] = useState(false)

  const isEmailValid = () => {
    const isValid = /\S+@\S+\.\S+/.test(email)
    setEmailValid(isValid)
    return isValid
  }

  const sendFirstResetEmail = (e) => {
    e.preventDefault()
    if (isEmailValid()) {
      authApi.sendResetEmail(email).then(() => {
        if (emailSent) {
          setEmailResent(true)
        }
        setEmailSent(true)
      })
    }
  }

  if (emailSent) {
    return (
      <AuthFormWrapper title="Email sent!">
        <Paragraph2>
          To reset your password, please click the link in the email we have
          sent to you. The email was sent to the following address:
        </Paragraph2>
        <Paragraph1Bold>{email}</Paragraph1Bold>
        <Paragraph2>Haven’t received the email?</Paragraph2>
        <Button secondary onClick={sendFirstResetEmail}>
          Resend email
        </Button>
        {emailResent && <LabelBig>Another email was sent to you</LabelBig>}
      </AuthFormWrapper>
    )
  }

  return (
    <AuthFormWrapper title="Reset your password">
      <Paragraph2>
        Please enter your email address and we will send you a link to reset
        your password.
      </Paragraph2>
      <form onSubmit={sendFirstResetEmail}>
        <InputField
          onChangeEvent={setEmail}
          placeholder="Your email address"
          errorMessage="Please enter a valid email address"
          error={!emailValid}
        />
        <Button type="submit">Send email</Button>
        <TextButton type="button" as="a" href="/login">
          Cancel
        </TextButton>
      </form>
    </AuthFormWrapper>
  )
}

export default ResetMailSender
