import styled from 'styled-components'

const Container = styled.div`
  background: ${(p) => p.theme.colors.grays[5]};
  border-radius: 20px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
`

export default Container
