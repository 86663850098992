import React, { useState } from 'react'
import { Paragraph2 } from '../../design-system/typography'
import { IconButton, Line } from '../../design-system/utils'
import ActionDots from '../../svgs/ActionDots'
import { Options, Wrapper } from './style'

type EditDropdownProps = {
  editLabel: string
  deleteLabel: string
  editAction: () => void
  deleteAction: () => void
}

function EditDropdown({
  editLabel,
  deleteLabel,
  deleteAction,
  editAction,
}: EditDropdownProps) {
  const [open, setOpen] = useState(false)

  const onEdit = () => {
    editAction()
    setOpen(false)
  }

  const onDelete = () => {
    deleteAction()
    setOpen(false)
  }

  return (
    <Wrapper>
      <IconButton onClick={() => setOpen((isOpen) => !isOpen)}>
        <ActionDots />
      </IconButton>
      <Options open={open}>
        <Paragraph2 onClick={onEdit}>{editLabel}</Paragraph2>
        <Line />
        <Paragraph2 onClick={onDelete}>{deleteLabel}</Paragraph2>
      </Options>
    </Wrapper>
  )
}

export default EditDropdown
