import React, { useEffect, useState } from 'react'
import clubApi from '../../../api/clubs'
import EditDropdown from '../../../components/EditDropdown'
import { dateToMonthYear } from '../../../utils/DateConverter'
import ClubEditor from '../ClubEditor'
import ClubInfoHeader from '../ClubInfoHeader'
import {
  ActionsWrapper,
  AutoAssigned,
  Description,
  Divider,
  Wrapper,
} from './style'

type ClubCardProps = {
  club
  onUpdate: () => void
}

export default function ClubCard({ club, onUpdate }: ClubCardProps) {
  const [distance, setDistance] = useState(null)
  const [months, setMonths] = useState(null)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [clubData, setClubData] = useState(null)

  useEffect(() => {
    if (!club.assignDistances || club.assignDistances.length === 4) {
      setDistance('All')
    } else {
      setDistance(club.assignDistances.join(', '))
    }

    if (!club.assignDateFrom || !club.assignDateTo) {
      setMonths('All')
    } else {
      const from = dateToMonthYear(club.assignDateFrom)
      const to = dateToMonthYear(club.assignDateTo)
      setMonths(`${from} - ${to}`)
    }
  }, [club])

  const deleteClub = () => {
    clubApi.deleteClub(club.id).then(() => onUpdate())
  }

  const openEditPopup = () => {
    clubApi.getClubById(club?.id).then(({ data }) => setClubData(data))
    setEditModalOpen(true)
  }

  return (
    <>
      <Wrapper>
        <ActionsWrapper>
          <EditDropdown
            editLabel="Edit Club"
            deleteLabel="Delete Club"
            deleteAction={deleteClub}
            editAction={openEditPopup}
          />
        </ActionsWrapper>
        <ClubInfoHeader
          name={club.name}
          memberCount={club.memberCount}
          imageUrl={club.imageUrl}
          clubCode={club.clubCode}
          donated={club.elevated}
          key={club.id}
        />
        {club.description ? (
          <Description>{club.description}</Description>
        ) : (
          <Description noDescription>No description available</Description>
        )}
        <Divider />
      </Wrapper>
      {editModalOpen && clubData && (
        <ClubEditor
          defaultClub={clubData}
          onUpdate={onUpdate}
          closeModal={() => {
            setEditModalOpen(false)
            setClubData(null)
          }}
        />
      )}
    </>
  )
}
