import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import rewardsApi from '../../api/rewards'
import { Heading1Bold } from '../../design-system/typography'
import { PageWrapper } from '../../design-system/utils'
import CreateReward from './CreateReward'
import RewardCard from './RewardCard'
import { CreateButton, TitleWrapper } from './style'
import withAuthorization from '../../components/HOC/withAuthorization'

function Rewards() {
  const [rewards, setRewards] = useState([])
  const [triggerUpdate, setTriggerUpdate] = useState(false)
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [unauthorized, setUnauthorized] = useState(false)

  useEffect(() => {
    rewardsApi
      .getAllRewards()
      .then((res) => setRewards(res.data))
      .catch((err) => {
        if (err.response.status === 401 || err.response.status === 403) {
          setUnauthorized(true)
        }
      })
  }, [triggerUpdate])

  if (unauthorized) {
    return <Redirect to="/login" />
  }

  return (
    <PageWrapper>
      <TitleWrapper>
        <Heading1Bold>Rewards</Heading1Bold>
        <CreateButton secondary onClick={() => setCreateModalOpen(true)}>
          + Create a reward
        </CreateButton>
      </TitleWrapper>
      {rewards.map((reward) => (
        <RewardCard
          reward={reward}
          key={reward.id}
          triggerUpdate={() => setTriggerUpdate((trigger) => !trigger)}
        />
      ))}
      {createModalOpen && (
        <CreateReward
          onClose={() => setCreateModalOpen(false)}
          onUpdate={() => setTriggerUpdate((trigger) => !trigger)}
        />
      )}
    </PageWrapper>
  )
}

export default withAuthorization(Rewards)
