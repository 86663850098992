import React from 'react'
import { useLocation } from 'react-router-dom'

import { Heading4Bold } from '../../design-system/typography'
import Wrapper, { LoginLink, MarketLink } from './style'
import Arrow from '../../assets/icons/arrow-left.svg'

type AuthFormWrapperProps = {
  title?: string
  children: React.ReactNode
}

function AuthFormWrapper({ children, title }: AuthFormWrapperProps) {
  const { pathname } = useLocation()
  return (
    <Wrapper>
      <div>
        {pathname === '/login' ? (
          <LoginLink to="/purchase">
            <img src={Arrow} alt="arrow" />
            Back to Sign Up
          </LoginLink>
        ) : (
          <MarketLink href="https://coopah.squarespace.com/tcs-london-marathon-24-a">
            <img src={Arrow} alt="arrow" />
            Back
          </MarketLink>
        )}

        <Heading4Bold>{title}</Heading4Bold>
      </div>
      {children}
    </Wrapper>
  )
}

export default AuthFormWrapper
