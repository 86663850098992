import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  > img {
    margin-right: ${(p) => p.theme.spacing.sm};
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;

  > svg {
    margin-right: 5px;
  }
`

export const InfoLabel = styled.div`
  display: flex;
  margin-right: ${(p) => p.theme.spacing.m};

  > p {
    margin-right: 5px;
  }
`
