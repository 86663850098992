import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Redirect } from 'react-router-dom'
import clubApi from '../../api/clubs'
import Loader from '../../components/Loader'
import { Heading1Bold } from '../../design-system/typography'
import { Button, PageWrapper } from '../../design-system/utils'
import ClubCard from './ClubCard'
import ClubEditor from './ClubEditor'
import HeaderWrapper from './style'
import withAuthorization from '../../components/HOC/withAuthorization'

const LIMIT = 10

function Club() {
  const [clubs, setClubs] = useState([])
  const [unauthorized, setUnauthorized] = useState(false)
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [triggerUpdate, setTriggerUpdate] = useState(false)
  const [hasMore, setHasMore] = useState(true)

  useEffect(() => {
    setClubs([])
    clubApi
      .getClubsWithLimit({ limit: LIMIT, offset: 0 })
      .then((res) => {
        setClubs(res.data)
        if (res.data.length < LIMIT) {
          setHasMore(false)
        } else {
          setHasMore(true)
        }
      })
      .catch((err) => {
        if (err.response.status === 401 || err.response.status === 403) {
          setUnauthorized(true)
        }
      })
  }, [triggerUpdate])

  const loadMoreClubs = () => {
    clubApi
      .getClubsWithLimit({ limit: LIMIT, offset: clubs.length })
      .then((res) => {
        if (res.data.length < LIMIT) {
          setHasMore(false)
        }
        setClubs((loadedClubs) => [...loadedClubs, ...res.data])
      })
      .catch((err) => {
        if (err.response.status === 401 || err.response.status === 403) {
          setUnauthorized(true)
        }
      })
  }

  if (unauthorized) {
    return <Redirect to="/login" />
  }

  return (
    <PageWrapper>
      <HeaderWrapper>
        <Heading1Bold>Club</Heading1Bold>
        <Button
          secondary
          type="button"
          onClick={() => setCreateModalOpen(true)}
        >
          + New Club
        </Button>
      </HeaderWrapper>
      <InfiniteScroll
        dataLength={clubs.length}
        next={loadMoreClubs}
        hasMore={hasMore}
        loader={<Loader />}
        style={{ overflow: 'visible' }}
        scrollThreshold={0.8}
      >
        {clubs.map((club) => (
          <ClubCard
            key={club.id}
            club={club}
            onUpdate={() => setTriggerUpdate((update) => !update)}
          />
        ))}
      </InfiniteScroll>
      {createModalOpen && (
        <ClubEditor
          closeModal={() => setCreateModalOpen(false)}
          onUpdate={() => setTriggerUpdate((update) => !update)}
        />
      )}
    </PageWrapper>
  )
}

export default withAuthorization(Club)
