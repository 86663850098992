import React from 'react'
import {
  ImageWrap,
  TextBlockWrapper,
  PurchaseWrap,
  PageWrapper,
} from './styles'
import Purchase from '../../assets/images/purchase.png'

const PurchaseFailed = () => {
  return (
    <PageWrapper>
      <PurchaseWrap>
        <div>
          <ImageWrap src={Purchase} alt="purchase" />
        </div>
        <TextBlockWrapper>
          <h1>You already have a subscription. </h1>
          <p>
            You already have an existing Coopah subscription. We are sorry, but
            this offer is only available to those without a subscription.
          </p>

          <p>
            Please contact us on{' '}
            <a href="mailto:run@coopah.com">run@coopah.com</a> if you have any
            questions.
          </p>
          <p>
            Back to <a href="https://coopah.com">Coopah</a>
          </p>
        </TextBlockWrapper>
      </PurchaseWrap>
    </PageWrapper>
  )
}

export default PurchaseFailed
