import styled from 'styled-components'
import { IconButton } from '../../../design-system/utils'

const OrderIcons = styled(IconButton)`
  position: absolute;
  top: -4px;
  bottom: 0;
  left: 0;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`

export default OrderIcons
