import React from 'react'
import { IconButton } from '../../design-system/utils'
import EmptyCheckbox from '../../svgs/EmptyCheckbox'
import FilledCheckBox from '../../svgs/FilledCheckBox'

type CheckboxProps = {
  checked: boolean
  toggleCheck: (e: React.MouseEvent) => void
}

function CheckBox({ checked, toggleCheck }: CheckboxProps) {
  return (
    <IconButton type="button" onClick={toggleCheck}>
      {checked ? <FilledCheckBox /> : <EmptyCheckbox />}
    </IconButton>
  )
}

export default CheckBox
