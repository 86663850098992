import React, { useCallback, useEffect, useState, useContext } from 'react'
import { Redirect } from 'react-router-dom'
import authApi from '../../../api/auth'
import AuthFormWrapper from '../../../components/AuthFormWrapper'
import InputField from '../../../components/InputField'
import {
  ErrorMessage,
  Paragraph2,
  Paragraph1Bold,
} from '../../../design-system/typography'
import { Button } from '../../../design-system/utils'
import Checkmark from '../../../svgs/Checkmark'
import useQuery from '../../../utils/useQuery'
import StatusMessageWrapper from './style'
import { AuthContext } from '../AuthProvider'

function PasswordReset() {
  const { isAuthorized } = useContext(AuthContext)

  const [email, setEmail] = useState('')
  const [code, setCode] = useState('')
  const [password, setPassword] = useState('')
  const [passwordRepeat, setPasswordRepeat] = useState('')
  const [error, setError] = useState(false)
  const [passwordsMatchPattern, setPasswordsMatchPattern] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [success, setSuccess] = useState(false)
  const [linkExpired, setLinkExpired] = useState(false)
  const [emailSent, setEmailSent] = useState(false)

  const query = useQuery()

  useEffect(() => {
    setEmail(query.get('email'))
    setCode(query.get('code'))
  }, [query])

  const validateResetCode = useCallback((emailParam, codeParam) => {
    authApi.validateResetCode(codeParam, emailParam).then((res) => {
      if (!res.data) {
        setLinkExpired(true)
      }
    })
  }, [])

  useEffect(() => {
    if (query.get('email') && query.get('code')) {
      validateResetCode(query.get('email'), query.get('code'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateResetCode])

  const checkPasswordsMatch = () => {
    const areMatching = password === passwordRepeat
    setError(!areMatching)
    return areMatching
  }

  const checkPasswordsMatchPattern = () => {
    const isLongEnough = password.length >= 8
    setPasswordsMatchPattern(isLongEnough)
    return isLongEnough
  }

  const changePassword = (e) => {
    e.preventDefault()
    setPasswordsMatchPattern(true)
    if (!checkPasswordsMatch()) {
      setErrorMessage(`Passwords don't match`)
      return
    }

    if (!checkPasswordsMatchPattern()) {
      return
    }

    authApi
      .resetPassword(email, password, code)
      .then((res) => {
        if (res.data) {
          setSuccess(true)
        } else {
          setLinkExpired(true)
        }
      })
      .catch((err) => {
        setLinkExpired(true)
      })
  }

  const isEmailValid = () => {
    const isValid = /\S+@\S+\.\S+/.test(email)
    return isValid
  }

  const sendFirstResetEmail = (e) => {
    e.preventDefault()
    if (isEmailValid()) {
      authApi.sendResetEmail(email).then(() => {
        setEmailSent(true)
      })
    }
  }

  if (isAuthorized) {
    return <Redirect to="/" />
  }

  if (emailSent) {
    return (
      <AuthFormWrapper title="Email sent!">
        <Paragraph2>
          To reset your password, please click the link in the email we have
          sent to you. The email was sent to the following address:
        </Paragraph2>
        <Paragraph1Bold>{email}</Paragraph1Bold>
        <Paragraph2>Haven’t received the email?</Paragraph2>
        <Button secondary onClick={sendFirstResetEmail}>
          Resend email
        </Button>
      </AuthFormWrapper>
    )
  }

  if (success) {
    return (
      <AuthFormWrapper>
        <StatusMessageWrapper>
          <Checkmark />
          <Paragraph2>Your password has been reset</Paragraph2>
        </StatusMessageWrapper>
      </AuthFormWrapper>
    )
  }

  if (linkExpired) {
    return (
      <AuthFormWrapper>
        <StatusMessageWrapper>
          <Paragraph2>The link is expired, please try again.</Paragraph2>
        </StatusMessageWrapper>
        <Button onClick={sendFirstResetEmail}>Resend reset link</Button>
      </AuthFormWrapper>
    )
  }

  return (
    <AuthFormWrapper title="New password">
      <Paragraph2>Password must be at least 8 characters long</Paragraph2>
      <form onSubmit={changePassword}>
        <InputField
          placeholder="Your new password"
          type="password"
          onChangeEvent={setPassword}
          error={!passwordsMatchPattern}
          errorMessage="Password doesn’t fit the requirements"
        />
        <InputField
          placeholder="Repeat your new password"
          type="password"
          onChangeEvent={setPasswordRepeat}
          error={!passwordsMatchPattern}
          errorMessage="Password doesn’t fit the requirements"
        />
        {error && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <Button type="submit">Reset password</Button>
      </form>
    </AuthFormWrapper>
  )
}

export default PasswordReset
