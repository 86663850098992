import { title } from 'process'
import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import channelApi from '../../../api/channels'
import clubApi from '../../../api/clubs'
import Checkbox from '../../../components/Checkbox'
import DatePicker from '../../../components/DatePicker'
import ImageUploader from '../../../components/ImageUploader'
import InputField from '../../../components/InputField'
import Modal from '../../../components/Modal'
import MultipleSelectChip from '../../../components/MultiSelectDropDown'
import { MultipleSelectItemProps } from '../../../components/MultiSelectDropDown/MultipleSelectChip'
import Switch from '../../../components/Switch'
import {
  Heading2Bold,
  Paragraph1Bold,
  Paragraph2,
} from '../../../design-system/typography'
import { Button, TextButton } from '../../../design-system/utils'
import ClubApiData from '../../../types/ClubApiData'
import Distances from '../../../types/Distances'
import {
  Form,
  InputFields,
  Error,
  CheckBoxWrapper,
  DropdownWrapper,
  SubHeader,
  SwitchWrapper,
  SwitchLabel,
} from './style'

type ChatEditorProps = {
  closeModal: () => void
  onUpdate: () => void
  defaultChat?
}

function ChatEditor({ closeModal, onUpdate, defaultChat }: ChatEditorProps) {
  const [error, setError] = useState(null)
  const [unauthorized, setUnauthorized] = useState(false)
  const [file, setFile] = useState(null)
  const [name, setName] = useState(defaultChat?.name || '')
  const [description, setDescription] = useState(defaultChat?.description || '')
  const [autoAssign, setAutoassign] = useState(defaultChat?.autoAssign || false)
  const [assignByDistance, setAssignByDistance] = useState(true)
  const [assignByClubs, setAssignByClubs] = useState(true)
  const [assignByDate, setAssignByDate] = useState(true)
  const [assignDistances, setAssignDistances] = useState(
    defaultChat?.assignDistances || []
  )

  const [assignDateFrom, setAssignDateFrom] = useState(
    defaultChat?.assignDateFrom ? new Date(defaultChat?.assignDateFrom) : null
  )
  const [assignDateTo, setAssignDateTo] = useState(
    defaultChat?.assignDateTo ? new Date(defaultChat?.assignDateTo) : null
  )

  const [clubs, setClubs] = React.useState<MultipleSelectItemProps[]>([])

  useEffect(() => {
    window.scrollTo(0, 0)
    clubApi
      .getAllClubs()
      .then((res) => {
        setClubs(
          res.data.map(({ id, clubCode, name: clubName }) => ({
            key: id,
            subTitle: clubCode,
            title: clubName,
          }))
        )
      })
      .catch((err) => {
        if (err.response.status === 401 || err.response.status === 403) {
          setUnauthorized(true)
        }
      })
  }, [])
  const [selectedClubs, setSelectedClubs] = useState<MultipleSelectItemProps[]>(
    []
  )

  useEffect(() => {
    setSelectedClubs(
      clubs.filter(({ key }) => defaultChat?.assignClubs?.includes(Number(key)))
    )
  }, [clubs, defaultChat?.assignClubs])

  const initAssignedClubs = (assignClubIds: number[]) => {
    return clubs.filter(({ key }) => assignClubIds.includes(Number(key)))
  }

  const toggleMultiSelectClubs = (clubItem: MultipleSelectItemProps) => {
    if (selectedClubs.includes(clubItem)) {
      setSelectedClubs((prevSelectedClubs) =>
        prevSelectedClubs.filter(
          (prevSelectedClub) => prevSelectedClub.key !== clubItem.key
        )
      )
    } else {
      setSelectedClubs((prevSelectedClubs) => [...prevSelectedClubs, clubItem])
    }
  }

  const toggleDistance = (distance) => {
    if (assignDistances.includes(distance)) {
      setAssignDistances((prevDistances) =>
        prevDistances.filter((dist) => dist !== distance)
      )
    } else {
      setAssignDistances((prevDistances) => [...prevDistances, distance])
    }
  }

  const toggleAssignByDistance = () => {
    if (assignByDistance) {
      setAssignByDistance(false)
      setAssignDistances([])
    } else {
      setAssignByDistance(true)
    }
  }
  const toggleAssignByClubs = () => {
    if (assignByClubs) {
      setAssignByClubs(false)
      setSelectedClubs([])
    } else {
      setAssignByClubs(true)
    }
  }

  const toggleAssignByDate = () => {
    if (assignByDate) {
      setAssignByDate(false)
      setAssignDateFrom(null)
      setAssignDateTo(null)
    } else {
      setAssignByDate(true)
    }
  }

  const sendData = () => {
    const assignClubs = selectedClubs.map((club) => club.key)
    if (defaultChat) {
      return channelApi.updateChannel({
        channelId: defaultChat.id,
        file,
        name,
        description,
        autoAssign,
        assignDistances,
        assignClubs,
        assignDateFrom,
        assignDateTo,
      })
    }

    return channelApi.createChannel({
      file,
      name,
      description,
      autoAssign,
      assignDistances,
      assignClubs,
      assignDateFrom,
      assignDateTo,
    })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    sendData()
      .then(() => {
        onUpdate()
        closeModal()
      })
      .catch((err) => {
        if (err.response.status === 422) {
          setError(
            'Cannot save changes. Please make that all required fields are filled.'
          )
        } else {
          setError('Something went wrong. Please try again.')
        }
      })
  }

  const setFromDate = (newDate: Date) => {
    setAssignDateFrom(newDate)
    if (assignDateTo && newDate > assignDateTo) {
      setAssignDateTo(newDate)
    }
  }

  if (unauthorized) {
    return <Redirect to="/login" />
  }

  return (
    <Modal onClose={closeModal} width="695px" enableBodyScroll={true}>
      <Heading2Bold>
        {defaultChat ? 'Edit channel' : 'Create a channel'}
      </Heading2Bold>
      <Form onSubmit={onSubmit}>
        <InputFields>
          <ImageUploader
            setImage={setFile}
            requiredError={error && !file}
            defaultImageUrl={defaultChat?.image}
          />
          <div>
            <InputField
              placeholder="Title"
              onChangeEvent={setName}
              errorMessage="Required"
              error={error && name === ''}
              defaultValue={name}
            />
            <InputField
              placeholder="Description"
              onChangeEvent={setDescription}
              errorMessage="Required"
              error={error && description === ''}
              defaultValue={description}
            />
            <SubHeader>Auto-assignment rules for this channel:</SubHeader>
            <CheckBoxWrapper main>
              <Checkbox
                checked={assignByDistance}
                toggleCheck={toggleAssignByDistance}
              />
              <Paragraph1Bold>Based on target race distance</Paragraph1Bold>
            </CheckBoxWrapper>
            {assignByDistance && (
              <>
                <CheckBoxWrapper>
                  <Checkbox
                    checked={assignDistances.includes(Distances.FIVEKM)}
                    toggleCheck={() => toggleDistance(Distances.FIVEKM)}
                  />
                  <Paragraph2>5 km</Paragraph2>
                </CheckBoxWrapper>
                <CheckBoxWrapper>
                  <Checkbox
                    checked={assignDistances.includes(Distances.TENKM)}
                    toggleCheck={() => toggleDistance(Distances.TENKM)}
                  />
                  <Paragraph2>10 km</Paragraph2>
                </CheckBoxWrapper>
                <CheckBoxWrapper>
                  <Checkbox
                    checked={assignDistances.includes(Distances.HALFMARATHON)}
                    toggleCheck={() => toggleDistance(Distances.HALFMARATHON)}
                  />
                  <Paragraph2>21 km</Paragraph2>
                </CheckBoxWrapper>
                <CheckBoxWrapper>
                  <Checkbox
                    checked={assignDistances.includes(Distances.MARATHON)}
                    toggleCheck={() => toggleDistance(Distances.MARATHON)}
                  />
                  <Paragraph2>42 km</Paragraph2>
                </CheckBoxWrapper>
              </>
            )}
            <CheckBoxWrapper main>
              <Checkbox
                checked={assignByDate}
                toggleCheck={toggleAssignByDate}
              />
              <Paragraph1Bold>Based on months</Paragraph1Bold>
            </CheckBoxWrapper>
            {assignByDate && (
              <DropdownWrapper>
                <DatePicker
                  label="From"
                  setDate={setFromDate}
                  selectedDate={assignDateFrom}
                />
                <DatePicker
                  label="Till"
                  setDate={setAssignDateTo}
                  selectedDate={assignDateTo}
                  minDate={assignDateFrom}
                />
              </DropdownWrapper>
            )}

            <CheckBoxWrapper main>
              <Checkbox
                checked={assignByClubs}
                toggleCheck={toggleAssignByClubs}
              />
              <Paragraph1Bold>Based on Clubs</Paragraph1Bold>
            </CheckBoxWrapper>

            {assignByClubs && (
              <DropdownWrapper>
                <MultipleSelectChip
                  data={clubs}
                  selectedData={selectedClubs}
                  onChange={toggleMultiSelectClubs}
                  label="Assign to Clubs"
                />
              </DropdownWrapper>
            )}

            <SwitchWrapper>
              <Switch
                checked={autoAssign}
                onChange={() => setAutoassign((assigned) => !assigned)}
              />
              <div>
                <Paragraph2>Auto-assignment</Paragraph2>
                <SwitchLabel>
                  Newly registered users will be assigned
                </SwitchLabel>
              </div>
            </SwitchWrapper>
          </div>
        </InputFields>
        {error && <Error>{error}</Error>}
        <Button secondary type="submit">
          {defaultChat ? 'Edit channel' : 'Create channel'}
        </Button>
        <TextButton type="button" onClick={closeModal}>
          Cancel
        </TextButton>
      </Form>
    </Modal>
  )
}

export default ChatEditor
