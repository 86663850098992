const spacing = {
  xs: '10px',
  sm: '20px',
  m: '30px',
  md: '40px',
  lg: '70px',
  xl: '80px',
  xxl: '120px',
}

const duration = {
  default: '0.3s',
}

const theme = {
  colors: {
    primary: '#F47021',
    primaryLight: '#FFF9F5',
    primaryDark: '#DB4032',
    success: '#83DBB6',
    error: '#EE2B24',
    black: '#000000',
    grays: [
      '#7F7F7F',
      '#BAB9B9',
      '#E3E3E3',
      '#EAEAEA',
      '#828282',
      '#E5E5E5',
      '#E2E2E2',
    ],
    white: '#FFFFFF',
  },
  fontFamily: {
    primary: 'Century Gothic',
  },
  fontWeights: {
    light: '300',
    regular: '400',
    bold: '700',
  },
  spacing,
  duration,
  boxShadow: '0px 5px 13px rgba(0, 0, 0, 0.18)',
  borderRadius: '8px',
  headerHeight: '95px',
}

export default theme
