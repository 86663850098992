import React from 'react'

function Eye() {
  return (
    <svg
      width="21"
      height="19"
      viewBox="0 0 21 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9806 1.53874C8.47648 -0.595527 2.2843 2.13632 0.15003 7.64049C0.104938 7.75678 0.104938 7.88572 0.15003 8.00201C1.23613 10.803 3.45078 13.0177 6.25177 14.1038C11.7559 16.238 17.9481 13.5062 20.0824 8.00201C20.1275 7.88572 20.1275 7.75678 20.0824 7.64049C18.9963 4.83949 16.7816 2.62484 13.9806 1.53874ZM19.0779 7.8213C17.0791 12.6893 11.5419 15.0825 6.6133 13.1714C4.13489 12.2104 2.16292 10.2746 1.15456 7.8212C3.15335 2.95323 8.69052 0.560015 13.6191 2.4711C16.0975 3.43212 18.0695 5.3679 19.0779 7.8213ZM10.1162 11.8212C7.90707 11.8212 6.11621 10.0304 6.11621 7.82125C6.11621 5.61211 7.90707 3.82125 10.1162 3.82125C12.3253 3.82125 14.1162 5.61211 14.1162 7.82125C14.1162 10.0304 12.3253 11.8212 10.1162 11.8212ZM13.1162 7.82125C13.1162 9.4781 11.7731 10.8212 10.1162 10.8212C8.45936 10.8212 7.11621 9.4781 7.11621 7.82125C7.11621 6.1644 8.45936 4.82125 10.1162 4.82125C11.7731 4.82125 13.1162 6.1644 13.1162 7.82125Z"
        fill="#BAB9B9"
      />
    </svg>
  )
}

export default Eye
