import axios from 'axios'
import ChannelApiData from '../types/ChannelApiData'

const getAllChannels = ({ limit, offset }) => {
  return axios.get(`/api/admin/chat/channels?limit=${limit}&offset=${offset}`, {
    withCredentials: true,
  })
}

const createChannel = ({
  file,
  name,
  description,
  autoAssign,
  assignDistances,
  assignClubs,
  assignDateFrom,
  assignDateTo,
}: ChannelApiData) => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('name', name)
  formData.append('description', description)
  formData.append('autoAssign', String(autoAssign))
  if (assignDistances.length > 0)
    formData.append('assignDistances', assignDistances.join(','))
  if (assignClubs.length > 0)
    formData.append('assignClubs', assignClubs.join(','))
  if (assignDateFrom)
    formData.append('assignDateFrom', assignDateFrom.toDateString())
  if (assignDateTo) formData.append('assignDateTo', assignDateTo.toDateString())
  return axios.post('/api/admin/chat/channel', formData, {
    withCredentials: true,
  })
}

const deleteChannel = (channelId) => {
  return axios.delete(`/api/admin/chat/channel/${channelId}`, {
    withCredentials: true,
  })
}

const updateChannel = ({
  channelId,
  file,
  name,
  description,
  autoAssign,
  assignDistances,
  assignClubs,
  assignDateFrom,
  assignDateTo,
}: ChannelApiData) => {
  const formData = new FormData()
  if (file) formData.append('file', file)
  formData.append('name', name)
  formData.append('description', description)
  formData.append('autoAssign', String(autoAssign))
  formData.append('assignDistances', assignDistances.join(','))
  formData.append('assignClubs', assignClubs.join(','))

  if (assignDateFrom) {
    formData.append('assignDateFrom', assignDateFrom.toDateString())
  } else {
    formData.append('assignDateFrom', '')
  }
  if (assignDateTo) {
    formData.append('assignDateTo', assignDateTo.toDateString())
  } else {
    formData.append('assignDateTo', '')
  }

  return axios.put(`/api/admin/chat/channel/${channelId}`, formData, {
    withCredentials: true,
  })
}

const channelApi = {
  getAllChannels,
  createChannel,
  deleteChannel,
  updateChannel,
}

export default channelApi
